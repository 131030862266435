/*Service Node Responsive stylesheet*/
/*This stylesheet is based on basic four breakpoints of Bootstraps. Additional breakpoint might be used.*/
/*Also this has been developed on Dahsboard3 css*/

/*Extra Small or Mobile Devices*/
@media (max-width: 767px) {
  .header-search {
    position: absolute;
    left: 15px;
    bottom: 15px;
  }

  .rbt-dropdown-user {
    min-width: 275px;
  }

  .al-wrap {
    width: 35px;
  }

  .al-text {
    width: calc(100% - 60px);
    padding-top: 0;
  }

  .activity-logo {
    width: 35px;
    height: 35px;
  }

  .navbar-static-top {
    box-shadow: none;
    border-bottom: 1px solid #ddd;
  }

  .navbar-top-links {
    float: right;
    display: inline-block;
    border-left: 1px solid #ddd;
    position: unset;
  }

  .navbar-top-links:after {
    content: none;
  }

  .navbar-top-links > li > a {
    padding: 0 15px;
    min-height: 60px;
    line-height: 60px;
  }

  .navbar-top-links .dropdown-menu {
    right: 0;
    left: auto;
  }

  .navbar-top-links .dropdown-menu li a {
    border-right: 0;
    border-left: 0;
  }

  .navbar-top-links .open > a,
  .navbar-top-links .open > a:focus,
  .navbar-top-links .open > a:hover {
    background-color: #288cdc;
    border-color: #288cdc;
  }

  .navbar-profile-picture {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    vertical-align: text-top;
    margin-top: -8px;
  }

  .navbar-toggle {
    padding: 23px 20px;
    border: 0;
    border-radius: 0;
    margin: 0;
  }

  .navbar-default .navbar-toggle:focus,
  .navbar-default .navbar-toggle:hover {
    background-color: #288cdc;
  }

  .navbar-default .navbar-toggle:focus .icon-bar,
  .navbar-default .navbar-toggle:hover .icon-bar {
    background: #ffffff;
  }

  .sidebar-nav {
    background: #5eb3f7;
  }

  .navbar-fixed-top .navbar-collapse.sidebar-nav {
    max-height: inherit;
  }

  .navbar-default.sidebar {
    background: transparent;
  }

  .top-navbar-header {
    position: relative;
  }

  .top-navbar-header .navbar-top-links > li {
    position: unset;
  }

  .navbar-brand {
    float: left;
  }

  #page-wrapper {
    padding: 60px 0 0 0;
  }

  .header {
    position: relative;
    background: #fff;
    margin: 20px 20px 0;
    border-radius: 4px;
    -webkit-box-shadow: 1px 1px 3px #bfbfbf;
    -moz-box-shadow: 1px 1px 3px #bfbfbf;
    box-shadow: 1px 1px 3px #bfbfbf;
    z-index: 0;
  }

  .sn-input-group input.form-control,
  .sn-input-group textarea.form-control,
  .asset-add-form .input-group.date,
  .asset-add-form .input-daterange.input-group {
    width: 100%;
  }

  .file-upload {
    margin-bottom: 20px;
  }

  .login-panel {
    width: 100%;
    margin: 20px auto 0 auto;
  }

  .login-panel .panel-body {
    padding: 20px;
  }

  .account-title {
    margin-top: auto;
  }

  .sidebar .sidebar-nav.navbar-collapse {
    padding-bottom: 20px;
    width: 100%;
  }

  .su-nav-dropdown {
    padding: 20px 15px !important;
  }

  .noDataFound {
    font-size: 20px;
  }

  .preview-image,
  .preview-details {
    float: none !important;
  }

  .preview-details {
    width: 100%;
  }

  .details-table > tbody > tr > th,
  .details-table > tbody > tr > td {
    display: block;
  }

  .heading-right {
    margin-top: 5px;
  }

  .heading-right .pull-right {
    float: none !important;
  }

  .created-for-select label {
    display: block;
  }

  .gutter-20 [class^="col-"].form-separator,
  .gutter-20 [class^=" col-"].form-separator {
    padding-right: 10px;
  }

  .heading-right-list {
    text-align: left;
    margin-left: 1px;
    margin-top: 15px;
  }

  .heading-right-list li {
    display: block;
    border: 0;
    padding: 0;
    margin-bottom: 10px;
  }

  .modal.in .modal-dialog {
    -webkit-transform: translate(0, 1%);
    -moz-transform: translate(0, 1%);
    -ms-transform: translate(0, 1%);
    -o-transform: translate(0, 1%);
    transform: translate(0, 1%);
  }

  .reg-success-page {
    max-width: 300px;
    padding: 20px;
  }

  .event-calendar-col,
  .event-add-col {
    float: none;
    width: 100%;
    margin-bottom: 20px;
  }

  .my-page-profile-picture {
    width: 60px;
  }

  .account-title-name {
    display: inline-block;
    margin-left: 20px;
  }

  .filter-wrap {
    text-align: left;
    margin-top: 20px;
  }

  .filter-wrap > li {
    padding-left: 0;
    padding-right: 8px;
  }

  .filter-wrap .table-search {
    margin-top: 20px;
    width: 100% !important;
  }

  .notification-heading {
    height: auto;
  }

  .dropdown.sidebar {
    display: inline-block !important;
  }

  .mobile-menu {
    background: #484848;
    border-radius: 0;
    padding: 0;
  }
}

/*Small to Larger Devices*/
@media (min-width: 768px) {
  .sidebar {
    margin-top: 0;
    width: 175px;
    top: 0;
  }

  #page-wrapper {
    margin: 0 0 0 175px;
    padding: 60px 0 0 0;
  }
}

/*Small Device or Tablet Devices*/
@media (min-width: 768px) and (max-width: 991px) {
  .preview-image img {
    width: 100px;
  }

  .heading-right-list {
    text-align: left;
    margin-left: 0px;
    margin-top: 15px;
  }

  .heading-right-list li {
    padding: 0;
    display: block;
    border: 0;
    margin-bottom: 10px;
  }

  .event-calendar-col,
  .event-add-col {
    float: none;
    width: 100%;
    margin-bottom: 20px;
  }

  .canvas-col {
    float: left;
    width: calc(100% - 220px) !important;
  }

  .attributes-col {
    float: right;
    width: 200px !important;
  }
}

/*Medium Devices*/
@media (min-width: 992px) and (max-width: 1199px) {
}

/*Larger Screens or Desktop and Laptop Screens*/
@media (min-width: 1200px) {
}

/*Fix Table Dropdown cut out inside table responsive*/
@media (max-width: 991px) {
  .table-responsive {
    overflow-y: auto;
  }
}
@media (min-width: 992px) {
  .table-responsive {
    overflow: visible;
  }
}

/*Strictly handling medias*/
@media (max-width: 372px) {
  .navbar-brand {
    margin: 20px 10px;
    height: 20px;
  }
}
