$primary-border-radius-base: 4px;
$details-card-height: 480px;
$side-bar-width: 185px;
$primary-margin: 12px;
$primary-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2),
  0 1px 2px 0 rgba(0, 0, 0, 0.06);

$component-background: #fff;

$font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$code-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
  monospace;

//Color
$primary-color: #42a5f5;
$primary-color-error: #f50;
$primary-color-warning: #ff9800;
$primary-color-success: #5ac102;
$primary-color-processing: #42a5f5;

$primary-border: 1px solid #f0f0f0;

$task-color: #bae637;
$workorder-color: #5ac102;
$error-color: "#f50";
$serviceplanrecur-color: "#42a5f5";
$entityform-color: "#fadb14";

$font-size-base: 14px;
$text-color-secondary: rgba(0, 0, 0, 0.45);
$text-color: rgb(33, 43, 54);
$heading-color: rgba(0, 0, 0, 0.85);
$border-color-split: #f0f0f0;
$input-placeholder-color: hsv(0, 0, 75%);
//Avartar
$avatar-size-base: 32px;
$avatar-size-lg: 40px;
$avatar-size-sm: 24px;
$avatar-font-size-base: 18px;
$avatar-font-size-lg: 24px;
$avatar-font-size-sm: 14px;
$avatar-bg: #ccc;
$avatar-color: #fff;

// Border color
$border-color-base: hsv(0, 0, 85%); // base border outline a component
$border-color-split: hsv(0, 0, 94%); // split border inside a component
$border-width-base: 1px; // width of the border for a component
$border-style-base: solid; // style of a components border

// Media queries breakpoints
// Extra small screen / phone
$screen-xs: 480px;
$screen-xs-min: $screen-xs;

// Small screen / tablet
$screen-sm: 576px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 768px;
$screen-md-min: $screen-md;

// Large screen / wide desktop
$screen-lg: 992px;
$screen-lg-min: $screen-lg;

// Extra large screen / full hd
$screen-xl: 1200px;
$screen-xl-min: $screen-xl;

// Extra extra large screen / large desktop
$screen-xxl: 1600px;
$screen-xxl-min: $screen-xxl;

// provide a maximum
$screen-xs-max: ($screen-sm-min - 1px);
$screen-sm-max: ($screen-md-min - 1px);
$screen-md-max: ($screen-lg-min - 1px);
$screen-lg-max: ($screen-xl-min - 1px);
$screen-xl-max: ($screen-xxl-min - 1px);

$pro-header-hover-bg: rgba(0, 0, 0, 0.025);
$shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08),
  0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
$popover-bg: #fff;
$item-active-bg: $primary-color;
$error-color: #f50;

.list-load-more-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
