@import "../../../../sass/base.scss";
.sn-auth-layout-cointainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: #f0f2f5;
  .sn-auth-layout-content-header {
    margin-bottom: 22px;
    font-size: 28px;
  }
  .sn-auth-layout-content {
    flex: 1 1;
    &-header {
      text-align: center;
    }
    &-main {
      width: 360px;
      margin: 0 auto;
      text-align: center;
      &-card {
        box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        .ant-card-head {
          background-color: #3f3f3f;
          color: #fff;
        }
        .ant-card-cover {
          padding-top: 15px;
          justify-content: center;
          display: flex;
        }
        .ant-card-body {
          padding: 10px 35px 25px 35px;
          .ant-input-affix-wrapper,
          .ant-input {
            border-radius: 18px;
          }

          .sn-button {
            border-radius: 25px;
          }
        }
        .ant-card-actions {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          > li {
            margin: 0;
            .ant-btn {
              height: 50px;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 768px) {
  // .sn-auth-layout-cointainer {
  //   background-color: $primary-color;
  //   background-image: url(../../../../assets/img/bg_pattern.png);
  // }
  .sn-auth-layout-content {
    padding: 50px 0 24px;
    align-items: center;
    display: flex;
  }
}

@media (max-width: $screen-xs) {
  .sn-auth-layout-content {
    &-main {
      width: 340px !important;
    }
  }
}
