@import "components/ImageCropper/cropper.min.css";
@import "assets/css/bootstrap.min.css";
@import "assets/css/styles.css";
@import "assets/css/responsive.css";
@import "~antd/dist/antd.css";
/* @import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css"; */
body {
  margin: 0;
  padding: 0;
}
html body #root {
  height: 100%;
  margin: 0;
  padding: 0;
}
/*cropperStyles*/
.modal.in .sn-cropper {
  -webkit-transform: translate(0, 10%);
  -ms-transform: translate(0, 10%);
  -o-transform: translate(0, 10%);
  transform: translate(0, 10%);
}

.image-gallery {
  list-style: none;
  display: inline-block;
  width: 100%;
  height: 420px;
  overflow-y: auto;
}

.image-gallery > li {
  width: calc(100% / 6 - 20px);
  border: 1px dashed #3c3c3c;
  margin: 10px;
  display: inline-flex;
  overflow: hidden;
  height: 120px;
  float: left;
  background: #eee;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
}

.image-gallery > li > img {
  max-width: 100% !important;
  max-height: 100% !important;
  margin: auto;
}

.image-gallery > li.selected {
  border: 1px dashed #42a5f5;
}

.image-gallery > li.selected::after {
  content: "\f058";
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  font-size: 40px;
  top: 0;
  color: #42a5f5;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(50, 53, 62, 0.44);
  text-align: center;
  padding-top: 30px;
  border-radius: 4px;
}

.image-add-btn {
  font-size: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer !important;
  -webkit-transition: all cubic-bezier(0.47, 0, 0.75, 0.72) 0.2s;
  -moz-transition: all cubic-bezier(0.47, 0, 0.75, 0.72) 0.2s;
  -ms-transition: all cubic-bezier(0.47, 0, 0.75, 0.72) 0.2s;
  -o-transition: all cubic-bezier(0.47, 0, 0.75, 0.72) 0.2s;
  transition: all cubic-bezier(0.47, 0, 0.75, 0.72) 0.2s;
}

.image-add-btn:hover {
  background: #41a5f7;
  color: #ffff;
}

@media (min-width: 0px) and (max-width: 479px) {
  .image-gallery > li {
    width: calc(100% / 2 - 24px);
  }
}

@media (min-width: 480px) and (max-width: 576px) {
  .image-gallery > li {
    width: calc(100% / 3 - 24px);
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .image-gallery > li {
    width: calc(100% / 4 - 24px);
  }
}
/*end cropperStyles*/

/*imageGalleryStyles*/
.list-img-gallery {
  padding-left: 0;
  margin-left: -8px;
  height: auto;
}

.list-img-gallery > li {
  width: 68px;
  height: 68px;
  margin: 8px;
  overflow: visible;
}

.list-img-gallery.empty > li {
  font-size: 30px;
  text-align: center;
  display: inline-block;
  line-height: 68px;
  color: #757575;
  cursor: auto;
}

.list-img-gallery.clearable > li {
  cursor: auto;
}

.list-img-gallery.clearable > li > span {
  position: absolute;
  right: -6px;
  width: 20px;
  height: 20px;
  line-height: 21px;
  background: #e64a19;
  text-align: center;
  cursor: pointer;
  color: #fff;
  top: -8px;
  border-radius: 50%;
}

.list-img-gallery > li.table-image {
  width: 57px;
  height: 57px;
  margin: 0 8px;
  border: 1px solid #37474f;
  border-radius: 0;
}
/*end imageGalleryStyles*/

/*Lightbox css*/
.lightbox-modal.modal-dialog {
  width: auto;
  transform: none !important;
  margin: 0;
}

.lightbox-modal.modal-dialog > .modal-content {
  background: transparent;
  box-shadow: none;
  border: none;
}

.lightbox-modal.modal-dialog > .modal-content > .modal-body {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 90px;
}

.lightbox-caption {
  color: #fff;
  font-size: 13px;
  margin-top: 10px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
}

.lightbox-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 20px;
}

.lightbox-close {
  font-size: 22px;
  color: #fff;
  position: absolute;
  top: -40px;
  right: 0;
  cursor: pointer;
}
/*end Lightbox css*/

/*scheduleDetails css*/
.schedule-modal-dialog > .modal-content {
  max-width: 360px;
  min-height: 400px;
  position: relative;
  padding-bottom: 50px;
}

.schedule-desc-body input.form-control,
.schedule-desc-body textarea.form-control,
.schedule-desc-body select.form-control {
  border-color: #42a5f5;
}

.schedule-desc-body .input-group-addon {
  min-width: 44px;
  border-color: #42a5f5;
  background-color: #42a5f5;
  color: #fff;
}

.schedule-desc-body .Select-control,
.schedule-desc-body .is-open > .Select-control,
.schedule-desc-body .Select-menu-outer {
  border: 1px solid #42a5f5;
}

.resetEventDetailHeight > .modal-content {
  min-height: auto;
}

.schedule-desc-body .dp-addon {
  background-color: #42a5f5;
  color: #ffffff;
}
/*end scheduleDetails css*/

/*badge css*/
.badge {
  background-color: #7f7f7f;
  min-width: 18px;
  height: 17px;
  line-height: 18px;
  padding: 0 3px;
  font-size: 11px;
}

.blue-badge {
  background-color: #42a5f5;
}

.red-badge {
  background-color: #e0581c;
}

.green-badge {
  background-color: #5ac102;
}

.yellow-badge {
  background-color: #feb405;
}
/*end badge css*/

.list-placeholder {
  width: 57px;
  height: 57px;
  border: 1px solid #bdbdbd;
  display: flex;
  color: #777;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

.heading-right-list li {
  min-height: 26px;
  vertical-align: text-top;
  line-height: 25px;
}

.tr-hover,
.rbt-table > tbody > .tr-hover:hover {
  box-shadow: none !important;
  background-color: rgba(33, 150, 243, 0.23921568627450981);
}

.table-action-list {
  background: #f2f2f2;
  padding: 10px 20px;
  margin-left: 0;
}

.table-action-list > li:last-child {
  padding-right: 0;
}

a {
  cursor: pointer;
}

.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.sn-prev-wrap {
  padding: 14px;
  background: #ddd;
}

.sn-prev-wrap .preview {
  margin: 0 auto;
}
.file-dropzone {
  width: 100%;
  border: 2px dashed #42a5f5;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  cursor: pointer;
}

.file-dropzone .drop-text {
  text-align: center;
  color: #6f6f6f;
}
.file-dropzone .drop-text h3 {
  margin-top: 0;
  font-size: 40px;
}
.file-dropzone .drop-text small {
  font-size: 90%;
}
.file-dropzone .drop-text i {
  font-size: 60px;
  margin: 25px auto;
}
.file-dropzone .drop-text p {
  font-size: 20px;
}

.list-heading {
  margin: 0 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label-with-btn {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.detail-heading {
  background: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
  box-shadow: 1px 1px 3px #bfbfbf;
  display: flex;
  padding: 17px 20px;
  align-items: center;
  justify-content: space-between;
}

.detail-heading > .detail-heading-left section,
.detail-heading > .detail-heading-right section {
  margin-left: 10px;
}

.detail-heading > .detail-heading-left section:first-child,
.detail-heading > .detail-heading-right section:first-child {
  margin-left: 0;
}

.detail-heading > .detail-heading-left,
.detail-heading > .detail-heading-right {
  display: flex;
  align-items: center;
  /*flex: .5*/
}

.detail-heading > .detail-heading-right {
  justify-content: flex-end;
}

.detail-heading .panel-title {
  display: block;
}

.heading-dropdown > .btn-default {
  min-height: 38px;
  border-color: #e6e6e6;
  color: #777;
}

.k-pdf-export .pdf-details-page [class^="col-"] {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.k-pdf-export .pdf-details-page .asset-full {
  min-height: auto !important;
}

.k-pdf-export .pdf-details-page .asset-full > .panel-body {
  height: auto !important;
}

.ml10 {
  margin-left: 10px !important;
}

.list-toolbar {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-flow: wrap;
}

.list-toolbar .toolbar-left,
.list-toolbar .toolbar-right {
  display: flex;
  align-items: center;
}

.list-toolbar .toolbar-left section {
  margin-right: 20px;
}

.list-toolbar .toolbar-right section {
  margin-left: 10px;
}
.list-toolbar .toolbar-right section:not(:last-child) {
  margin-right: 10px;
}

.list-toolbar .toolbar-right:empty {
  display: none;
}

.label_add_new {
  display: flex;
  justify-content: space-between;
}

/*Responsive styles*/
@media (max-width: 767px) {
  .list-heading {
    display: flex;
    justify-content: unset;
    align-items: baseline;
    flex-direction: column;
  }

  .table-search {
    width: 100% !important;
  }

  .detail-heading > .detail-heading-left,
  .detail-heading > .detail-heading-right {
    flex-direction: column;
    align-items: baseline;
  }

  .detail-heading > .detail-heading-left section,
  .detail-heading > .detail-heading-right section {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .list-toolbar .toolbar-left section,
  .list-toolbar .toolbar-right section {
    margin-top: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 991px) {
  .asset-full > .panel-body {
    height: auto;
  }

  .detail-heading,
  .list-toolbar {
    flex-direction: column;
    align-items: baseline;
  }

  .list-toolbar {
    padding-top: 5px;
  }

  .list-toolbar .toolbar-left,
  .list-toolbar .toolbar-right {
    flex-flow: wrap;
  }

  .list-toolbar .toolbar-left section,
  .list-toolbar .toolbar-right section {
    flex-flow: wrap;
    margin-top: 15px;
  }

  .list-toolbar .toolbar-right {
    border-left: 0;
  }

  .list-toolbar .toolbar-right section {
    margin-left: 0;
    margin-right: 20px;
  }
}

/*Animations*/
.fade-appear {
  opacity: 0;
  z-index: 1;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 300ms linear;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear;
}

.fade-exit {
  opacity: 0;
}
.fade-exit-active {
  opacity: 1;
  transition: opacity 300ms linear;
}

.navbar-top-links li > button.dropdown-toggle {
  min-height: 60px;
  background: transparent;
  color: #41a5f7;
  border-radius: 0;
  min-width: 55px;
}

.navbar-top-links li > button.dropdown-toggle:focus,
.navbar-top-links li > button.dropdown-toggle:hover {
  background: #eee;
}

.btn.btn-link {
  padding: 0;
}
