@import "../../../sass/base.scss";
.sn-btn-big {
  min-width: 100px;
  height: 34px;
  padding: 4px 14px;
}
.ant-btn-link {
  padding: 0;
  margin-left: 4px;
}
.sn-button {
  .svg-inline--fa + span {
    margin-left: 6px;
  }
  i + span {
    margin-left: 6px;
  }
  svg {
    width: 14px !important;
  }
}
.sn-btn-icon-trans {
  border: none;
  box-shadow: none;
  background-color: transparent;
}
.sn-btn-icon {
  background-color: #fbfbfb;
}
.ant-btn-icon-only {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  &.ant-btn-link {
    &:active,
    &:focus,
    &:hover {
      color: #096dd9 !important;
    }
  }
}
.sn-btn-purple {
  background: #f9f0ff !important;
  border: 1px solid #722ed1 !important;
  .anticon {
    color: #722ed1;
  }
  &:active,
  &:focus,
  &:hover {
    border: 1px solid #722ed1 !important;
  }
}
.sn-btn-green {
  background: #f6ffed !important;
  border: 1px solid #52c41a !important;
  color: #52c41a !important;
  .anticon {
    color: #52c41a !important;
  }
  &:active,
  &:focus,
  &:hover {
    border: 1px solid #52c41a !important;
  }
}
.sn-btn-blue {
  background: #e6f7ff !important;
  border: 1px solid #e6f7ff !important;
  .anticon {
    color: #1890ff;
  }
  .fal {
    color: #1890ff !important;
  }
  &:active,
  &:focus,
  &:hover {
    border: 1px solid #1890ff !important;
  }
}
.sn-btn-red {
  background: #fff1f0 !important;
  border: 1px solid #fff1f0 !important;

  .fal,
  .far,
  .fas,
  svg,
  .anticon {
    color: #f5222d !important;
  }
  &:active,
  &:focus,
  &:hover {
    border: 1px solid #f5222d !important;
  }
}

@media (min-width: $screen-xs) and (max-width: $screen-md) {
  .md-hidden-title {
    .ant-typography {
      display: none;
    }
  }
}
@media (min-width: $screen-xs) and (max-width: $screen-xl) {
  .lg-hidden-title {
    .ant-typography {
      display: none;
    }
  }
}
.ant-btn-link {
  &.sn-button {
    display: inline-block;
    &:hover {
      .ant-typography {
        color: $primary-color;
        text-decoration: underline !important;
      }
    }
  }
}
.ant-btn[disabled] {
  svg {
    color: rgba(0, 0, 0, 0.25) !important;
  }
}
