.calendar-toolbar {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

@media (min-width: $screen-xs) {
  .calendar-toolbar {
    flex-direction: row;
    justify-content: space-between;
  }
}
.calendar-container {
  & .fc {
    --fc-list-event-dot-width: 8px;
    --fc-border-color: rgba(145, 158, 171, 0.24);
    --fc-event-border-color: #74caff;
    --fc-now-indicator-color: #ff4842;
    --fc-today-bg-color: rgba(145, 158, 171, 0.16);
    --fc-page-bg-color: #fff;
    --fc-neutral-bg-color: #f4f6f8;
    --fc-list-event-hover-bg-color: rgba(145, 158, 171, 0.08);
    --fc-highlight-color: rgba(0, 171, 85, 0.08);
  }
  & .fc .fc-license-message {
    display: none;
  }
  & .fc a {
    color: $text-color;
  }
  //Table Head
  & .fc .fc-col-header {
    box-shadow: inset 0 -1px 0 var(--fc-border-color, #ddd);

    & th {
      border-color: transparent;
    }
    & .fc-col-header-cell-cushion {
      font-weight: 600;
      line-height: 1.57143;
      padding: 13px 0px;
    }
  }

  //Event
  & .fc .fc-event {
    border-color: transparent;
    background-color: transparent;
  }
  & .fc .fc-event .fc-event-main {
    padding: 2px 4px;

    border-color: transparent;
    background-color: rgb(255, 255, 255);
    transition: filter 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      filter: brightness(0.92);
    }
    &::before,
    &::after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";

      position: absolute;
      box-sizing: border-box;
    }
    &::before {
      z-index: 8;
      opacity: 0.32;
      border: 1px solid currentcolor;
    }
    &::after {
      z-index: 7;
      opacity: 0.24;
      background-color: currentColor;
    }
  }
  & .fc .fc-event .fc-event-main-frame {
    font-size: 13px;
    line-height: 20px;
    filter: brightness(0.24);
  }
  & .fc .fc-daygrid-event .fc-event-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & .fc .fc-event .fc-event-time {
    padding: 0;
    overflow: unset;
    font-weight: 900;
  }
  // Month View
  & .fc .fc-day-other .fc-daygrid-day-top {
    opacity: 1;
    & .fc-daygrid-day-number {
      color: rgb(145, 158, 171);
    }
  }
  & .fc .fc-daygrid-day-number {
    line-height: 1.57143;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    padding: 8px 8px 0px;
  }
  & .fc .fc-daygrid-event {
    margin-top: 4px;
  }
  & .fc .fc-daygrid-event.fc-event-start,
  & .fc .fc-daygrid-event.fc-event-end {
    margin-left: 4px;
    margin-right: 4px;
  }
  & .fc .fc-daygrid-more-link {
    line-height: 1.5;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    color: $primary-color;
  }

  // Week & Day View
  & .fc .fc-timegrid-axis-cushion {
    line-height: 1.57143;

    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    color: rgb(99, 115, 129);
  }
  & .fc .fc-timegrid-slot-label-cushion {
    line-height: 1.57143;

    font-family: "Public Sans", sans-serif;
    font-weight: 400;
  }

  // Agenda View

  & .fc .fc-list-table {
    border-color: transparent;
  }
}
