.select-table-modal-form-container {
  margin-top: 12px;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.select-table-modal-form-container-open {
  height: 100%;
}
.select-table-modal-form-container-closed {
  height: 0%;
}
.select-table-modal {
  width: calc(100vw - 350px) !important;
  min-width: 800px;

  .ant-modal-body {
    min-height: calc(100vh - 160px);
    width: calc(100vw - 350px);
    min-width: 800px;

    overflow-y: auto;
    overflow-x: hidden;
    padding: 24px 24px 0 24px;
  }
}

.selected-items-list-popover {
  .ant-popover-inner-content {
    max-height: 500px;
    width: 370px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .ant-popover-title {
    text-align: end;
  }
}
