.notify-day-number {
  width: 60px;
}
.sn-line-center {
  display: flex;
  align-items: center;
}
.sn-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sn-btn-hoverable {
  cursor: pointer;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  will-change: transform;
  &:hover {
    transform: scale(1.2);
  }
}
@font-face {
  font-family: "DejaVu Sans";
  src: url("https://kendo.cdn.telerik.com/2020.1.219/styles/fonts/DejaVu/DejaVuSans.ttf")
    format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  src: url("https://kendo.cdn.telerik.com/2020.1.219/styles/fonts/DejaVu/DejaVuSans-Bold.ttf")
    format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2020.1.219/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf")
    format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2020.1.219/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf")
    format("truetype");
}

::-moz-placeholder {
  color: #bfbfbf !important;
  opacity: 0.8 !important;
}
:-ms-input-placeholder {
  color: #bfbfbf !important;
  opacity: 0.8 !important;
}
::-webkit-input-placeholder {
  color: #bfbfbf !important;
}
::placeholder {
  color: #bfbfbf !important;
  opacity: 0.9 !important;
}

/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  display: none;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

kendo-pdf-export {
  font-family: "DejaVu Sans", "Arial", sans-serif;
}

.sn-bg-grey {
  background-color: #fbfbfb;
  border: 1px solid #e8e8e8;
}

label {
  font-weight: normal;
}

.spacer {
  clear: both;

  &[data-height="60"] {
    height: 60px;
  }

  &[data-height="70"] {
    height: 70px;
  }

  &[data-height="30"] {
    height: 30px;
  }

  &[data-height="96"] {
    height: 96px;
  }

  &[data-height="20"] {
    height: 20px;
  }
}

.sn-border {
  border: 1px solid #d9d9d9;
}

.sn-dropdown-item-selected {
  background-color: $primary-color;
  border-radius: 4px;
  padding: 5px 12px;
  span {
    color: #fff;
  }
  &:hover {
    span {
      color: #000;
    }
  }
}

.custom-select-option {
  display: flex;
}

.custom-select-option {
  > .option-img {
    border: 1px solid #e0e0e0;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    margin-right: 6px;

    > img {
      max-width: 32px;
      max-height: 32px;
    }
  }
  > .option-text {
    line-height: 18px;
    font-size: 14px;

    color: rgba(0, 0, 0, 0.85);
    > span {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  > .option-right-text {
    float: right;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.hoverable {
  cursor: pointer;
}

.sn-dynamic-delete-button {
  font-size: 20px;
  position: relative;
  top: 2px;
  color: #999;
  transition: all 0.3s;
  cursor: pointer;
  text-align: center;
  margin-left: 10px;
  &:hover {
    color: #888;
  }
}

.sn-dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.sn-calendar-more-link {
  font-weight: 900;
}
.fc-popover {
  padding: 8px 12px;
  border-radius: 12px;
  .fc-popover-header {
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
    .fc-popover-title {
      font-weight: 600;
      font-size: 16px;
      text-align: center !important;
    }
    .fc-popover-close {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;
    }
  }
}

.full-height {
  height: 100%;
}
.sn-btn-link {
  cursor: pointer;
  transition: all 0.3s;
  color: #00000073;
  &:hover {
    color: #595959;
  }
}

.text-ellipsis {
  display: inline-block;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.location-tag {
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 8px;
}
.sn-map-marker-icon {
  margin-bottom: 2px;
  margin-right: 2px;
  z-index: 1;
  font-size: 18px !important;
  font-weight: 900;
}

.sn-underline-link {
  text-decoration: underline !important;
  &:hover {
    color: #42a5f5;
  }
}

.sn-avatar-line-container {
  display: flex;
  flex: 1;
  align-items: flex-start;
  max-width: 100%;
  .sn-avatar-line-text {
    margin-top: 4px;
  }
}

.sn-link {
  cursor: pointer;
  color: $primary-color;
  &:hover {
    color: $primary-color;
    text-decoration: underline !important;
    .ant-typography {
      text-decoration: underline !important;
    }
  }
}

.sn-list-same-card {
  .ant-col {
    height: calc(100% - 16px);
  }
}
.edit-hovering-container {
  display: flex;
  align-items: center;
  .edit-hovering-container-content {
    flex: 1;
  }
  .edit-hovering-container-extra {
    float: right;
    margin-left: auto;
    padding: 16px 0;
    color: #000000d9;
    font-weight: 400;
    font-size: 14px;

    opacity: 0;
    &.visible {
      svg {
        color: #1890ff;
      }
      opacity: 1;
    }
  }
  &:hover {
    .edit-hovering-container-extra {
      opacity: 1;
    }
  }
}

.qrcode-wrap {
  border-radius: 6px;
  border: 2px solid #333;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  width: fit-content;
  height: fit-content;
  padding: 6px;
  .qrcode {
  }
}

.sn-infinity-clickable-list {
  .sn-list-item-action {
    display: none;
  }
  .ant-list-item {
    &.selected {
      .sn-list-item-action {
        display: block;
      }
    }
  }
}

.sn-title {
  color: $primary-color;
  outline: none;
  font-size: 16px;
  transition: color 0.3s;
  text-decoration: none;
}
.sn-simple-table {
  .ant-table-thead {
    .ant-table-cell {
      background: #fff;
    }
  }
}
.site-tree-search-value {
  color: #f50;
}

.sn-row-checkbox-option {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 6px;
  .ant-checkbox {
    order: 2;
  }
  &:hover {
    color: $primary-color;
  }
  &.odd {
    background-color: #f5f5f5;
  }
}

.sn-general-details-container {
  section {
    margin-bottom: 12px;
  }
  .sn-list-toolbar-container {
  }
}

@media (min-width: $screen-md) {
  .sn-details-container {
    > .ant-col:first-child {
      border-right: $primary-border;
    }
  }
}
.sn-details-modal {
  .ant-modal-header {
    display: none;
  }
  .ant-modal-body {
    padding: 14px 24px 24px;
  }
  .ant-modal-close {
    top: 5px;
  }
}

.warning-container {
  .ant-layout-sider,
  .ant-layout-header,
  .sn-content-header {
    margin-top: 40px !important;
  }

  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}
