.sn-main-layout {
  .ant-pro-global-header {
    padding: 0 0 0 12px;
  }
  .ant-pro-basicLayout-content {
    margin: 4px;
    padding: 6px 10px 6px 10px;
    overflow: hidden;
  }
  .ant-pro-sider-collapsed-button {
    border: none;
    .anticon {
      color: #fff;
    }
  }
  .ant-pro-basicLayout-content .ant-pro-page-container {
    margin: -30px -24px 0;
  }
}

@media (min-width: 768px) {
  .sn-main-layout {
    .sn-base-menu {
      height: auto;
    }
  }
}

// Right content
.sn-layout-right-content {
  display: flex;
  float: right;
  height: 64px;
  margin-left: auto;
  overflow: hidden;

  .ant-space-item {
    display: flex;
    align-items: center;
    height: 64px;

    .ant-dropdown-trigger {
      padding: 0 15px;
      color: $text-color;
    }
    cursor: pointer;
    transition: all 0.3s;

    > sn-icon {
      vertical-align: middle;
      color: $text-color;
    }
    > span {
      vertical-align: middle;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.025) !important;
      > .sn-button {
        background: rgba(0, 0, 0, 0.025) !important;
      }
    }

    .ant-badge-count {
      top: -3px;
      right: -1px;
    }
    &:last-child {
      padding: 0;
    }
  }
  .sn-company-user-menu-display {
    height: 100%;
    border: none;

    .sn-company-user-menu-display-meta {
      height: 42px;
      max-width: 200px;
      display: flex;
      align-items: center;

      .ant-card-meta-avatar {
        padding-right: 6px;
      }
      .ant-card-meta-detail {
        .ant-card-meta-title {
          font-size: 14px;
          margin-bottom: 0;
          line-height: 18px;
          text-align: initial;
        }
        .ant-card-meta-description {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-align: initial;
        }
      }
    }
  }
}
// Right content user menu
.sn-menu-user-list-company {
  .ant-dropdown-menu-item-group-title {
    display: none;
  }
  .ant-dropdown-menu-item-group-list {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;
  }
  border-bottom: $primary-border;
}
.company-menu-item {
  height: 52px;
  max-width: 230px;

  display: flex;
  align-items: center;

  .ant-card-meta-avatar {
    padding-right: 6px;
  }
  .ant-card-meta-detail {
    .ant-card-meta-title {
      font-size: 14px;
      margin-bottom: 0;
      line-height: 18px;
    }
    .ant-card-meta-description {
      font-size: 12px;
    }
  }
  .ant-image-img {
    display: inherit;
  }
}
.sn-menu-user-list-company-item {
  border-bottom: $primary-border;

  &:last-child {
    border-bottom: none;
  }
}
.sn-menu-user-list-company-item-selected {
  background-color: $primary-color;
  border-radius: 4px;
  border: none;
  .company-menu-item {
    .ant-card-meta-detail {
      .ant-card-meta-title,
      .ant-card-meta-description {
        color: #fff;
      }
    }
  }
  &:hover {
    .company-menu-item {
      .ant-card-meta-detail {
        .ant-card-meta-title,
        .ant-card-meta-description {
          color: inherit;
        }
      }
    }
  }
}
.sn-layout-header-dropdown-user,
.sn-layout-header-dropdown-shortcut {
  position: fixed !important;
}

// Menu
.ant-pro-sider {
  background-color: #262626 !important;
}
// Base Menu
.sn-base-menu {
  > .ant-menu-item {
    &:last-child {
      .ant-menu-title-content {
        border-top: $primary-border;
        margin-top: 1px;
      }
    }
  }

  background-color: #262626 !important;
  .ant-menu-submenu {
    .ant-menu-submenu-title {
      padding-left: 16px !important;
    }
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    height: 40px;
    color: #dbdbdb;
    padding-left: 16px !important;
    a {
      color: #dbdbdb;

      span {
        color: #dbdbdb;
        font-family: "Roboto";
      }
      .sn-icon {
        color: #dbdbdb;
        margin-right: 6px;
        width: 15px;
      }
    }

    &:hover {
      background-color: #209cda !important;
      a {
        span,
        .sn-icon {
          color: #ffffff !important;
        }
      }
    }
  }
  .ant-menu-submenu-title {
    padding: 0 !important;
    > a {
      padding: 0 16px;
    }
  }
  .ant-menu-item-selected {
    background-color: #209cda !important;

    span,
    .sn-icon {
      color: #ffffff !important;
    }
  }
  .ant-menu-submenu-selected {
    span,
    .sn-icon {
      color: #ffffff !important;
    }
  }

  .sn-side-bar-icon-extra {
    position: absolute;
    right: 37px;
    width: 10px;
    top: 2px;
  }
  .sn-side-bar-icon-extra-subitem {
    position: absolute;
    right: 30px;
    top: 12px;
  }
}
// Collapsed menu
.sn-base-vertical-menu {
  background-color: #262626 !important;

  > .ant-menu-item {
    &:last-child {
      .ant-menu-title-content {
        border-top: $primary-border;
        margin-top: 1px;
      }
    }
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    height: 40px;
    color: #dbdbdb;
    a {
      color: #dbdbdb;

      span {
        color: #dbdbdb;
        font-family: "Roboto";
      }
      .sn-icon {
        color: #dbdbdb;
        margin-right: 6px;
        width: 15px;
      }
    }

    &:hover {
      background-color: #209cda !important;
      a {
        span,
        .sn-icon {
          color: #ffffff !important;
        }
      }
    }
  }
  .ant-menu-item-selected,
  .ant-menu-submenu-selected {
    background-color: #209cda !important;

    span,
    .sn-icon {
      color: #ffffff !important;
      font-weight: 900;
    }
  }

  .sn-side-bar-icon-extra {
    position: absolute;
    right: 37px;
    width: 10px;
    top: 2px;
  }
  .sn-side-bar-icon-extra-subitem {
    position: absolute;
    right: 30px;
    top: 12px;
  }
}

.ant-menu-submenu-popup {
  position: fixed !important;
}

.ant-menu-dark,
.ant-menu.ant-menu-dark {
  .ant-menu-sub {
    background: #434343 !important;
    .ant-menu-item-selected {
      background-color: #209cda !important;
      a {
        > span {
          color: #fff !important;
        }
      }
      .sn-icon {
        font-weight: 900;
      }
    }

    .ant-menu-item {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      height: 40px;
      padding-left: 18px !important;
      a {
        > span {
        }
      }
      &:hover {
        background-color: #209cda !important;
        a {
          > span {
            color: #fff !important;
          }
        }
      }

      > a {
        > span {
          color: #ffffff !important;
          font-weight: 350;
        }
      }
    }
  }
}

.ant-layout-sider-collapsed {
  .ant-menu-item,
  .ant-menu-submenu-title {
    display: flex;
    justify-content: center;
    a {
      .sn-icon {
        font-size: 16px;
        width: 18px;
      }
      text-align: center;
    }
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    .ant-typography {
      display: none;
    }
  }
  .ant-tooltip,
  .ant-tooltip-inner {
    position: fixed;
  }
}

// Header Dropdown shortcuts

.sn-layout-header-dropdown-adding-shortcuts {
  width: 200px;
  position: fixed !important;
}
.sn-header-quick-add-dropdown-header {
  padding: 14px 10px;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
}
.sn-dropdown-menu-item {
  font-size: 15px !important;
  :hover {
    background-color: #fff;
  }
}
.sn-header-icon-dropdown-open {
  background: rgba(0, 0, 0, 0.025);
  color: $primary-color;
}
// Header notes dropdown

.notification-wrap-main {
  margin-left: 10px;
  display: flex;
}
.sn-list-item-actions {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 0;
  font-size: 0;
  list-style: none;
  > li {
    position: relative;
    display: inline-block;
    padding: 0 8px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
  }
  > li:first-child {
    padding-left: 0;
  }
  .sn-list-item-action-split {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 14px;
    margin-top: -7px;
    background-color: #f0f0f0;
  }
}

.sn-layout-header-dropdown-notification {
  width: 350px;
  position: fixed !important;
}
.sn-header-dropdown-index-container {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08),
    0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}

.sn-notification-list-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s;
  padding: 12px 4px 12px 0 !important;
  &:hover {
    background: #e6f7ff;
  }
  .sn-notification-list-item-meta {
    .ant-list-item-meta-avatar {
      margin-right: 0;
      .ant-avatar-image {
        width: 42px;
        height: 42px;
      }
    }
  }
  h4 {
    margin-top: 0;
    font-size: 13px;
    margin-bottom: 0;
  }

  .sn-notification-list-item-dot {
    margin: 8px 0 8px 8px;
    width: 14px;
    .ant-badge-status-dot {
      width: 8px;
      height: 8px;
    }
    &.read {
      .ant-badge {
        display: none;
      }
    }
  }
}
