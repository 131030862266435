/*SB Admin Theme CSS*/
/*!
 * Start Bootstrap - SB Admin 2 Bootstrap Admin Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

body {
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

form label {
  font-weight: 300;
}

p {
  margin-bottom: 0 !important;
}

#wrapper {
  width: 100%;
}

#page-wrapper {
  padding: 0 15px;
  min-height: 568px;
  background-color: #fff;
}

@media (min-width: 768px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0 250px;
    padding: 0 30px;
    border-left: 1px solid #e7e7e7;
  }
}

.invisible-extended {
  height: 1px !important;
  border: 0;
  width: 1px !important;
  opacity: 0;
  float: left;
}

.invisible-extended::after {
  clear: both;
}

.navbar-top-links {
  margin-right: 0;
  position: relative;
}

.navbar-top-links:after {
  content: "";
  position: absolute;
  width: 1px;
  background: #a2a2a2;
  height: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.navbar-top-links li {
  display: inline-block;
}

.navbar-top-links li:last-child {
  margin-right: 15px;
}

.navbar-top-links li.dropdown > a {
  display: flex;
  align-items: center;
  height: 60px;
}

.navbar-top-links li ul li a {
  min-height: 38px;
}

.navbar-top-links .dropdown-menu li {
  display: block;
}

.navbar-top-links .dropdown-menu li:last-child {
  margin-right: 0;
}

.navbar-top-links .dropdown-menu li a div {
  white-space: normal;
}

.navbar-top-links .dropdown-messages,
.navbar-top-links .dropdown-tasks,
.navbar-top-links .dropdown-alerts {
  width: 310px;
  min-width: 0;
}

.navbar-top-links .dropdown-messages {
  margin-left: 5px;
}

.navbar-top-links .dropdown-tasks {
  margin-left: -59px;
}

.navbar-top-links .dropdown-alerts {
  margin-left: -123px;
}

.navbar-top-links .dropdown-user {
  right: 0;
  left: auto;
}

.sidebar .sidebar-nav.navbar-collapse {
  padding-right: 0;
  padding-left: 0;
  background: #484848;
}

.sidebar .sidebar-search {
  padding: 15px;
}

.sidebar ul li {
  border-bottom: 1px solid #e7e7e7;
}

.sidebar ul li a.active {
  /* background-color: #eee; */
}

.sidebar .arrow {
  float: right;
}

.sidebar .fa.arrow:before {
  content: "\f104";
}

.sidebar .active > a > .fa.arrow:before {
  content: "\f107";
}

.sidebar .nav-second-level li,
.sidebar .nav-third-level li {
  border-bottom: 0 !important;
}

.sidebar .nav-second-level li a {
  padding-left: 37px;
}

.sidebar .nav-third-level li a {
  padding-left: 52px;
}

.margin-right-space {
  margin-right: 20px;
}

.margin-left-space {
  margin-left: 20px;
}

@media (min-width: 768px) {
  .sidebar {
    z-index: 1;
    position: absolute;
    width: 250px;
    margin-top: 51px;
  }

  .navbar-top-links .dropdown-messages,
  .navbar-top-links .dropdown-tasks,
  .navbar-top-links .dropdown-alerts {
    margin-left: auto;
  }
}

.btn {
  white-space: pre-line;
}

.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all 0.5s;
}

.text-sn-green {
  color: #5ac102;
}

.text-sn-yellow {
  color: #feb405;
}

.text-sn-red {
  color: #fe763a;
}

.text-sn-blue {
  color: #42a5f5;
}

.text-sn-dark-blue {
  color: #288cdc;
}

.text-sn-grey {
  color: #7f7f7f;
  font-weight: 300;
}

.text-description {
  white-space: pre-line;
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.btn-primary.btn-outline {
  color: #428bca;
}

.btn-success.btn-outline {
  color: #5cb85c;
}

.btn-info.btn-outline {
  color: #5bc0de;
}

.btn-warning.btn-outline {
  color: #f0ad4e;
}

.btn-danger.btn-outline {
  color: #d9534f;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
  color: #fff;
}

.chat {
  margin: 0;
  padding: 0;
  list-style: none;
}

.chat li {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px dotted #999;
}

.chat li.left .chat-body {
  margin-left: 60px;
}

.chat li.right .chat-body {
  margin-right: 60px;
}

.chat li .chat-body p {
  margin: 0;
}

.panel .slidedown .glyphicon,
.chat .glyphicon {
  margin-right: 5px;
}

.chat-panel .panel-body {
  height: 350px;
  overflow-y: scroll;
}

.login-panel {
  /*margin-top: 25%;*/
}

.flot-chart {
  display: block;
  height: 400px;
}

.flot-chart-content {
  width: 100%;
  height: 100%;
}

.dataTables_wrapper {
  position: relative;
  clear: both;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  background: 0 0;
}

table.dataTable thead .sorting_asc:after {
  content: "\f0de";
  float: right;
  font-family: "Font Awesome\ 5 Pro", "sans-serif";
}

table.dataTable thead .sorting_desc:after {
  content: "\f0dd";
  float: right;
  font-family: "Font Awesome\ 5 Pro", "sans-serif";
}

table.dataTable thead .sorting:after {
  content: "\f0dc";
  float: right;
  font-family: "Font Awesome\ 5 Pro", "sans-serif";
  color: rgba(50, 50, 50, 0.5);
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429;
}

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  border-radius: 25px;
  font-size: 18px;
  line-height: 1.33;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.show-grid [class^="col-"] {
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #ddd;
  background-color: #eee !important;
}

.show-grid {
  margin: 15px 0;
}

.huge {
  font-size: 40px;
}

.panel-green {
  border-color: #5cb85c;
}

.panel-green .panel-heading {
  border-color: #5cb85c;
  color: #fff;
  background-color: #5cb85c;
}

.panel-green a {
  color: #5cb85c;
}

.panel-green a:hover {
  color: #3d8b3d;
}

.panel-red {
  border-color: #d9534f;
}

.panel-red .panel-heading {
  border-color: #d9534f;
  color: #fff;
  background-color: #d9534f;
}

.panel-red a {
  color: #d9534f;
}

.panel-red a:hover {
  color: #b52b27;
}

.panel-yellow {
  border-color: #f0ad4e;
}

.panel-yellow .panel-heading {
  border-color: #f0ad4e;
  color: #fff;
  background-color: #f0ad4e;
}

.panel-yellow a {
  color: #f0ad4e;
}

.panel-yellow a:hover {
  color: #df8a13;
}

/*SB Admin Theme CSS End*/

/*Custom CSS*/

/*GENERAL STUFF*/
body {
  font-family: "Roboto", sans-serif;
  color: #3c3c3c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility !important;
}

img {
  max-width: 100%;
}

p {
  margin: 0;
}

a {
  color: #209dd9;
  text-decoration: none !important;
}

input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="textarea"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #777;
}

input[type="text"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="textarea"]::-moz-placeholder,
input[type="email"]::-moz-placeholder {
  /* Firefox 19+ */
  color: #777;
}

input[type="text"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="textarea"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder {
  /* IE 10+ */
  color: #777;
}

input[type="text"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
input[type="textarea"]:-moz-placeholder,
input[type="email"]:-moz-placeholder {
  /* Firefox 18- */
  color: #777;
}

.text-link {
  text-decoration: none !important;
  color: #333;
}

.text-link:hover,
.text-link:focus,
.text-link:active {
  color: #42a5f5;
}

.company-logo {
  max-height: 115px;
  max-width: 100%;
}

.data-text {
  font-weight: 400;
  color: #9e9e9e;
}

.divider {
  width: 100%;
  margin: 20px auto;
  height: 1px;
  background-color: #f2f2f2;
}

.form-divider {
  width: 100%;
  margin: 30px auto;
  height: 1px;
  background-color: transparent;
}

#map {
  height: 300px;
  margin-bottom: 15px;
}

.thumbnail-desc {
  margin-bottom: 15px;
}

#mypage-side-menu {
  margin-top: 10px;
}

#side-menu li a i,
#mypage-side-menu li a i {
  width: 29px;
  text-align: center;
}

.noDataFound {
  text-align: center;
  opacity: 0.6;
  font-size: 20px;
  padding-bottom: 20px;
}

.noDataFound i {
  opacity: 0.2;
}

.noDataFound-sm {
  text-align: center;
  opacity: 0.6;
  font-size: 20px;
}

.noDataFound-sm i {
  opacity: 0.2;
}

/*ASSIGN END*/
.sn-space {
  height: 20px;
}

.black-icon {
  color: #000;
}

.white-bg {
  background-color: #fff;
}

.transparent-bg {
  background-color: transparent;
}

/*.btn-sn {
    background-color: #209DD9;
    color: #fff !important;
    padding: 10px 25px;
    font-weight: 700;
    margin-left: 15px;
}*/

.form-doc-panel > a:hover,
.form-doc-panel > a:focus {
  text-decoration: none;
}

.form-doc-panel .panel-title {
  font-size: 18px;
}

.btn-sn-sm {
  background-color: #42a5f5 !important;
  color: #fff !important;
  padding: 5px 10px;
  font-weight: 700;
  margin-left: 15px;
}

.btn-sn-delete {
  background-color: #de4525 !important;
}

.btn-sn-white {
  color: rgba(0, 0, 0, 0.9) !important;
  background-color: #fff !important;
}

.btn-sn-cancel {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.btn-sn a {
  background-color: #209dd9 !important;
  color: #fff !important;
  font-weight: 700;
}

.top-shadow {
  box-shadow: inset 0 9px 17px -7px rgba(0, 0, 0, 0.4);
}

.activity-list-item {
  height: 85px;
}

.activity-list-item-info {
  position: relative;
  height: inherit;
}

.activity-list-item-info > .row:first-child {
  position: absolute;
  top: 20px;
}

.activity-list-item-info > .row:last-child {
  position: absolute;
  bottom: 0;
  font-style: italic;
}

.activity-list-item > .col-md-1 {
  height: inherit;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.activity-list-item .round-img {
  max-height: 85px;
}

.round-img {
  border-radius: 50% !important;
}

.panel-heading {
  border-bottom: 0;
}

.panel-footer {
  border-top: 0;
}

.panel-footer > .row:not(:last-child) {
  margin-bottom: 10px;
}

/*GENERAL STUFF END*/

/*Helper Classes*/
.prz {
  padding-right: 0;
}
.plz {
  padding-left: 0;
}
.ptz {
  padding-top: 0;
}
.pbz {
  padding-bottom: 0;
}
.plrz {
  padding-left: 0;
  padding-right: 0;
}
.ptbz {
  padding-top: 0;
  padding-bottom: 0;
}

.mrz {
  margin-right: 0;
}
.mlz {
  margin-left: 0;
}
.mtz {
  margin-top: 0;
}
.mbz {
  margin-bottom: 0;
}
.mlrz {
  margin-left: 0;
  margin-right: 0;
}
.mtbz {
  margin-top: 0;
  margin-bottom: 0;
}

.list-reset {
  margin-bottom: 0;
}

.list-reset li {
  padding-right: 0;
  padding-left: 15px;
}

.full-vh-height {
  height: 100vh;
  position: relative;
}

.left-radius {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.right-radius {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
/*Helper Classes End*/

/*Default Bootstrap Change*/
.form-group {
  margin-bottom: 20px;
}

input.form-control,
textarea.form-control,
select.form-control {
  height: 38px;
  border-color: #e6e6e6;
  box-shadow: none;
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  position: relative;
  text-overflow: "";
  background: url("../img/select-dropdown.png") no-repeat top 15px right 10px;
}

select.form-control::-ms-expand {
  display: none;
}

select.form-control:hover {
  cursor: pointer;
}

input.form-control:focus,
textarea.form-control:focus,
select.form-control:focus {
  border-color: #42a5f5;
  box-shadow: none;
}

textarea.form-control {
  max-width: 100%;
  min-height: 120px;
}

.input-group-addon {
  color: #333;
  border-color: #e6e6e6;
  background-color: #e6e6e6;
}

.modal-body {
  padding: 0;
}

.modal-inner {
  padding: 20px;
}

.modal-header {
  background-color: #42a5f5;
  color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/*.modal.in .modal-dialog {*/
/*    -webkit-transform: translate(0, 40%);*/
/*    -ms-transform: translate(0, 40%);*/
/*    -o-transform: translate(0, 40%);*/
/*    transform: translate(0, 40%);*/
/*}*/

.modal.in .modal-dialog-default {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.tooltip.top .tooltip-arrow {
  border-top-color: #42a5f5;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #42a5f5;
}

.tooltip.left .tooltip-arrow {
  border-left-color: #42a5f5;
}

.tooltip.right .tooltip-arrow {
  border-right-color: #42a5f5;
}

.tooltip-inner {
  background-color: #42a5f5;
}

.panel-body {
  padding: 20px 0;
}

.panel-body-no-padding {
  padding: 0;
}

.modal-inner {
  padding: 20px;
}

/*Custom Checkbox and Radio*/

.checkbox {
  padding-left: 20px;
}

.checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  min-height: 22px;
  font-weight: 300;
}

.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 22px;
  height: 22px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background-color: #fff;
}

.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 22px;
  height: 22px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #555555;
}

.checkbox input[type="checkbox"],
.checkbox input[type="radio"] {
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 22px;
  height: 22px;
  margin-top: 0px;
}

.checkbox input[type="checkbox"]:focus + label::before,
.checkbox input[type="radio"]:focus + label::before {
  outline: none;
}

.checkbox input[type="checkbox"]:checked + label::after,
.checkbox input[type="radio"]:checked + label::after {
  font-family: "Font Awesome\ 5 Pro", "sans-serif";
  content: "\f00c";
  color: #fff;
  background: #42a5f5;
  border-color: #42a5f5;
  border-radius: 2px;
  font-size: 15px;
}

.checkbox input[type="checkbox"]:indeterminate + label::after,
.checkbox input[type="radio"]:indeterminate + label::after {
  display: block;
  content: "";
  width: 10px;
  height: 3px;
  background-color: #555555;
  border-radius: 2px;
  margin-left: -16.5px;
  margin-top: 7px;
}

.checkbox input[type="checkbox"]:disabled + label,
.checkbox input[type="radio"]:disabled + label {
  opacity: 0.65;
}

.checkbox input[type="checkbox"]:disabled + label::before,
.checkbox input[type="radio"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}

.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox.checkbox-inline {
  margin-top: 0;
}

.radio {
  padding-left: 30px;
}

.radio label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
  padding-top: 5px;
}

.radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 22px;
  height: 22px;
  top: 1px;
  left: -7px;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 50%;
  background-color: #fff;

  -webkit-transition: border 0.3s ease-in-out;
  -o-transition: border 0.3s ease-in-out;
  transition: border 0.3s ease-in-out;
}

.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 7px;
  height: 7px;
  left: 0;
  top: 9px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #ffffff;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.radio input[type="radio"] {
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  top: -5px;
  width: 22px;
  height: 22px;
  margin-left: -27px;
}

.radio input[type="radio"]:focus + label::before {
  outline: none;
}

.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}

.radio input[type="radio"]:checked + label::before {
  border: 7px solid #42a5f5;
}

.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}

.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}

.radio.radio-inline {
  margin-top: 0;
}

.checkbox-inline + .checkbox-inline,
.radio-inline + .radio-inline {
  margin-left: 20px;
}

.radio-block {
  display: block;
  margin-left: 25px;
  margin-top: 0;
  margin-bottom: 15px;
}

/*Custom Checkbox and Radio End*/

.dropdown-menu {
  min-width: 210px;
  padding: 5px;
}

.dropdown-menu > li > a,
.dropdown-menu > li > button {
  color: #777;
  padding: 9px 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > button:focus,
.dropdown-menu > li > button:hover {
  color: #333;
  border-radius: 3px;
  background: #f1f1f1;
  outline: none;
}

.dropdown-menu > li > button {
  display: block;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  white-space: nowrap;
  background: transparent;
  border: 0;
  width: 100%;
  text-align: left;
}

.dropdown-menu li.divider {
  display: none;
}

.panel {
  -webkit-box-shadow: 1px 1px 3px #bfbfbf;
  -moz-box-shadow: 1px 1px 3px #bfbfbf;
  box-shadow: 1px 1px 3px #bfbfbf;
}

.panel-default {
  border-color: transparent;
}

/*Default Bootstrap Change End*/

/*TOOLTOP START*/
.hover-tooltip {
  color: #209dd9;
}

.tooltip-inner {
  max-width: 400px !important;
}

/*TOOLTIP END*/

/*NAVBAR START*/
.navbar-brand > img {
  height: 100%;
  width: auto;
}

.navbar {
  padding: 15px 0;
}

.navbar-brand {
  padding: 0 0 0 15px;
}

.navbar-static-top {
  margin-bottom: 0;
  -webkit-box-shadow: 0 10px 20px -10px rgba(170, 170, 170, 1);
  -moz-box-shadow: 0 10px 20px -10px rgba(170, 170, 170, 1);
  box-shadow: 0 10px 20px -10px rgba(170, 170, 170, 1);
}

/*NAVBAR END*/

/*PAGE WRAPPER HEADER START*/

header {
  -webkit-box-shadow: 10px 10px 20px -10px rgba(170, 170, 170, 1);
  -moz-box-shadow: 10px 10px 20px -10px rgba(170, 170, 170, 1);
  box-shadow: 10px 10px 20px -10px rgba(170, 170, 170, 1);
  background-color: #209dd9;
  color: #fff;
  width: 100%;
}

.header-title {
  margin-left: 20px;
}

.header-search {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.title {
  font-weight: 400;
}

.header {
  position: fixed;
  z-index: 1050;
  height: 60px;
  top: 0;
}

.header a {
  color: #fff;
}

.breadcrumb {
  background-color: transparent;
  color: #fff;
  padding: inherit;
  margin-bottom: 0;
}

.breadcrumb > .active {
  color: #fff;
  font-weight: 700;
}

/*PAGE WRAPPER HEADER END*/

/*PAGE WRAPPER START*/
.content {
  margin-top: 15px;
}

.panel-title {
  font-size: 26px;
  font-weight: 300;
}

.panel-heading {
  background-color: #fff !important;
}

.addNew {
  position: relative;
  vertical-align: middle;
  font-weight: 500;
  font-size: 20px;
  color: #209dd9;
  z-index: 50;
  margin: 0 20px 20px 20px;
}

.dashboard-panel > .panel-footer {
  min-height: 100px;
}

.addNew a {
  color: #209dd9;
}

.addNew a {
  vertical-align: middle;
}

/*PAGE WRAPPER END*/

/*FOOTER START*/
.footer {
  height: 80px;
  position: relative;
  bottom: 0;
  background-color: #f8f8f8;
  -webkit-box-shadow: 0 -10px 20px -10px rgba(170, 170, 170, 1);
  -moz-box-shadow: 0 -10px 20px -10px rgba(170, 170, 170, 1);
  box-shadow: 0 -10px 20px -10px rgba(170, 170, 170, 1);
  padding: 30px;
}

/*FOOTER END*/

.sn-color,
.label-primary {
  background-color: #42a5f5 !important;
}

.sn-color-red,
.label-danger {
  background-color: #f50 !important;
}

.sn-color-green,
.label-success {
  background-color: #5ac102 !important;
}

.sn-color-yellow,
.label-warning {
  background-color: rgb(255, 152, 0) !important;
}

/*Custom CSS End*/

/*TABLE START*/
.table-pages {
  vertical-align: middle;
  color: #777;
  float: right;
  margin-top: -15px;
}

.white-bg {
  background-color: #fff;
}

.transparent-bg {
  background-color: transparent;
}

.table-responsive table tbody tr:hover {
  background-color: #f1f1f1;
  -webkit-box-shadow: 0 10px 20px -10px rgba(170, 170, 170, 1);
  -moz-box-shadow: 0 10px 20px -10px rgba(170, 170, 170, 1);
  box-shadow: 0 10px 20px -10px rgba(170, 170, 170, 1);
}

.table-responsive table tbody td {
  vertical-align: middle;
}

.table-dropdown {
  text-align: right;
}

.table-link a {
  color: #288cdc;
}

.table-link a:hover {
  text-decoration: none;
}

/*TABLE END*/

/*My Page CSS*/
.account-title {
  color: #ffffff;
  background: #484848;
  padding: 15px 15px 12px;
  position: relative;
  text-align: center;
}

.account-title i {
  color: #209dd9;
}

.profile-edit-icon {
  background: #209cdb;
  width: 25px;
  height: 25px;
  position: absolute;
  text-align: center;
  opacity: 0;
  line-height: 25px;
  color: #fff;
  border-radius: 50px;
  right: 15px;
  box-shadow: 1px 1px 5px #7d7d7d;
  transition: opacity 0.2s;
}

.profile-edit-icon i {
  color: #fff;
}

.account-title:hover .profile-edit-icon {
  opacity: 1;
}

.account-info {
  color: #fff;
  padding: 25px 15px;
}

.account-info .row .col-xs-6:first-child {
  font-weight: bold;
}

.account-info .row .col-xs-6:last-child {
  color: #777777;
  text-align: right;
}

.account-info .row {
  padding-bottom: 15px;
}

.account-info .row:last-child {
  padding-bottom: 0;
}

.add-new-company {
  text-align: center;
  height: 285px;
  position: relative;

  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.add-new-company:hover {
  background-color: rgba(66, 165, 245, 0.3);
}

.add-new-company .panel-body {
  padding: 0;
}

.add-company-title {
  padding: 94px 0;
}

.edit {
  color: #209dd9;
  margin-right: 10px;
}

.company-card > .panel-body {
  padding: 20px;
}

.company-card > .panel-body .row:first-child {
  margin-bottom: 15px;
}

.company-card > .panel-body .row:first-child .col-xs-6 {
  height: 150px;
  position: relative;
}

.company-card > .panel-body p {
  color: #777777;
  font-size: 15px;
}

/*My Page CSS End*/

/*RBT CSS*/
input[type="file"] {
  height: auto;
}

#wrapper {
  position: relative;
  /*background: #209cda;*/
}

.rbt-dropdown-user {
  min-width: 360px;
  border: 5px solid #ddd;
  padding: 0;
}

.rbt-dropdown-user li {
  clear: both;
  padding-left: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #ddd;
}

.rbt-dropdown-user li:last-child {
  border-bottom: 0;
}

.rbt-dropdown-user li .glyphicon {
  color: #ccc;
  margin-right: 10px;
}

.rbt-dropdown-heading {
  background: #ddd;
  padding: 5px 15px;
  font-weight: 700;
  border-radius: 0 !important;
}

.rbt-panel-wrapper {
  padding: 15px;
  border: 1px solid #d7d7d7;
}

.content {
  padding-bottom: 20px;
}

.rbt-form-add-btn {
  background: #209dd9;
  color: #fff;
  border-color: #209dd9;
  border-radius: 0 !important;
  opacity: 0.8;
}

.rbt-form-add-btn:hover,
.rbt-form-add-btn:focus,
.rbt-form-add-btn:focus:active,
.rbt-form-add-btn:active,
.rbt-form-add-btn:active:hover {
  opacity: 1;
  background: #209dd9;
  color: #fff;
  border-color: #209dd9;
}

.rbt-modal-header {
  background-color: #42a5f5;
  color: #fff;
}

.content {
  padding-bottom: 20px;
}

.container-fluid.panel {
  margin-bottom: 0;
  padding: 20px 0;
  box-shadow: 1px 1px 3px #bfbfbf;
}

.container-fluid.pbz {
  padding-bottom: 5px;
}

.container-fluid.panel form {
  /*padding: 0 20px ;*/
}

.reg-heading {
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: #209dd9;
}

.navbar {
  min-height: 60px;
  height: 60px;
  padding: 0;
}

.btn.rbt-add-new-btn {
  background: #209dd9;
  color: #fff;
  padding-left: 5px;
  border-radius: 5px !important;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 19px;
  -webkit-transition: background 0.5s;
  -moz-transition: background 0.5s;
  -o-transition: background 0.5s;
  transition: background 0.5s;
}

.btn.rbt-add-new-btn:hover {
  background: #126f9c;
}

#page-wrapper,
header,
.navbar-static-top,
.top-shadow,
.table-responsive table tbody tr:hover {
  box-shadow: none;
}

.table-responsive table tbody tr:hover {
  box-shadow: none !important;
}

.footer {
  display: none;
}

.panel > .panel-collapse > .rbt-table,
.panel > .rbt-table,
.panel > .table-responsive > .rbt-table {
  margin-bottom: 20px;
}

.rbt-table th {
  font-size: 15px;
  color: #9c9c9c;
  font-weight: 300;
}

.rbt-table > thead > tr > th {
  border-bottom: 2px solid #f3f3f3;
}

.rbt-table > tbody > tr > td {
  border-top: 0;
  border-bottom: 1px solid #f3f3f3;
  padding: 10px 8px;
}

.rbt-table > tbody > tr > td:first-child {
  padding-left: 20px;
}

.rbt-table > thead > tr > th:first-child {
  padding-left: 20px;
}

.rbt-table > tbody > tr {
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.rbt-table td > img {
  max-height: 57px;
  max-width: 57px;
}

.rbt-table .table-dropdown {
  padding: 0 20px 0 8px;
  width: 20px;
}

.rbt-table .table-dropdown > .btn-group .dropdown-toggle {
  border: 1px solid #42a5f5;
  padding: 6px 10px;
  color: #42a5f5;
}

.rbt-table .table-dropdown > .btn-group.open .dropdown-toggle {
  background: #42a5f5;
  color: #fff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.rbt-table > tbody > tr > td.td-btn {
  text-align: right;
  padding: 10px 20px;
}

.table-dropdown .glyphicon {
  font-weight: 700;
}

.pagination {
  margin: 0;
}

.pagination > li {
  cursor: pointer;
}

/*.pagination li:not(:first-child):not(:last-child) {
    padding: 8px 12px;
}*/

.pagination > li > a,
.pagination > li > span {
  border: 0;
  color: #333;
  background-color: transparent;
  padding: 8px 14px;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li.active > a,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #333;
  background-color: #f1f1f1;
}

.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #f1f1f1;
  color: #333;
  padding: 8px 14px;
  border-radius: 3px;
}

.pagination > .disabled span,
.pagination > .disabled span:hover {
  color: #aaa;
}

.dataTables_info {
  margin: 0;
  padding: 8px 0;
  text-align: left;
}

.dataTables_paginate {
  text-align: right;
}

.dropdown-menu li i {
  width: 30px;
  text-align: center;
}

.white-bold-link {
  color: #fff;
  font-weight: 500;

  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
}

.white-bold-link:hover,
.white-bold-link:active {
  text-decoration: none;
  color: #333;
}

ul.errorlist {
  list-style: none;
  padding-left: 0;
  margin-bottom: -10px;
}

.notification-wrap {
  position: relative;
}

.notification-wrap a.dropdown-toggle {
  padding: 20px;
}

.notification-alert {
  position: absolute;
  font-size: 15px;
  right: 12px;
  top: 15px;
  color: #fe763a;
}

.notification-alert-list {
  height: 225px;
  background: white;
  padding: 10px 15px;
  overflow-y: auto;
}

.notification-alert-list li {
  margin-bottom: 5px;
  border-bottom: 1px solid #ddd;
  padding: 0 3px 7px;
}

.notification-alert-list li:last-child {
  border-bottom: 0;
}

.notification-alert-list li p {
  font-size: 13px;
  color: #607d8b;
}

.has-error .select2-selection--single {
  border: 1px solid #a94442 !important;
}

.account-title-name {
  font-size: 18px;
  line-height: 1.3em;
  margin: 15px 0 0;
  color: #fff;
  font-weight: 600;
}

.my-page-title-info {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
}

.edit-my-page-btn {
  background: #209dd9;
  border-color: #209dd9;
}

.form-divider {
  margin: 4px auto;
}

.activity-list-item {
  height: auto;
}

.activity-info-time {
  margin-top: 0;
  color: #777;
  display: block;
}

.al-wrap,
.al-text {
  float: left;
  display: inline-block;
}

.al-wrap {
  margin-right: 15px;
}

.al-text {
  width: calc(100% - 90px);
}

.al-text p {
  margin-bottom: 0;
}

.activity-logo {
  width: 55px;
  height: 55px;
  background: #f2f2f2 no-repeat center;
  background-image: url("../img/img-placeholder.jpg");
  background-size: 100%;
  border-radius: 50px;
  border: 1px solid #f2f2f2;
}

.activity-pagination .snode-pagination {
  padding-right: 15px;
  padding-bottom: 15px;
}

.newsfeed-item {
  /*cursor: pointer;*/
}

.newsfeed-item > .data-text {
  margin-bottom: 20px;
}

.newsfeed-item > .activity-info-time {
  text-decoration: none !important;
}

.reg-success-page {
  background: #fff;
  text-align: center;
  width: 250px;
  margin: 0 auto;
  /*padding: 40px;*/
  /*border-radius: 5px;*/
  position: relative;
  /*top: 50%;*/
  /*transform: translateY(-50%);*/
}

.reg-success-page h1 {
  font-weight: 300;
  font-size: 25px;
  color: #3bb54a;
}

.reg-success-page p {
  font-size: 17px;
  color: #777;
  margin-bottom: 20px;
}

.reg-page-btn {
  background: #209dd9;
  color: #fff !important;
  font-weight: 300;
  font-size: 20px;
  width: 250px;
  border-radius: 3px;

  -webkit-transition: background 0.3s;
  -moz-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s;
}

.reg-page-btn:hover,
.reg-page-btn:active {
  background: #337ab7;
}

.bootbox-close-button {
  margin-top: 0 !important;
}

.bootbox.modal .bootbox-body {
  padding: 20px;
  font-size: 15px;
}

.bootbox.modal .btn-primary {
  font-size: 14px;
  color: #fff;
  background-color: #42a5f5;
  border: 0;
  padding: 9px 12px;
  text-align: center;
  min-width: 100px;
  font-weight: 300;
  text-transform: capitalize;
  outline: none;

  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.bootbox.modal .btn-primary:hover,
.bootbox.modal .btn-primary.focus,
.bootbox.modal .btn-primary:focus {
  color: #fff;
  background-color: #5abeff;
  border: 0;
}

.bootbox.modal .btn-primary:active,
.bootbox.modal .btn-primary:active:hover {
  color: #fff;
  background-color: #288cdc;
  border: 0;
}

.bootbox.modal .btn-default {
  font-size: 14px;
  color: #333;
  background-color: #e6e6e6;
  border: 0;
  padding: 9px 12px;
  text-align: center;
  min-width: 100px;
}

.bootbox.modal .btn-default:hover,
.bootbox.modal .btn-default.focus,
.bootbox.modal .btn-default:focus {
  color: #333;
  background-color: #f2f2f2;
  border: 0;
}

.bootbox.modal .btn-default:active,
.bootbox.modal .btn-default:active:hover {
  color: #333;
  background-color: #777;
  border: 0;
}

.bootbox.modal .modal-footer {
  text-align: right;
  border-top: 0;
}

.file-btn {
  height: 50px;
  min-width: 50px;
  text-align: center;
  background: #209dd9;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  padding: 12px;
  border-radius: 2px;
  box-shadow: 0 0 1px #666;

  -webkit-transition: background 0.3s;
  -moz-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s;
}

.file-btn:hover,
.file-btn:focus {
  background: #337ab7;
  color: #fff !important;
}

.my-page-profile-picture {
  width: 95px;
}

.sn-ver {
  display: inline-block;
  position: absolute;
  left: 15px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  margin-top: -25px;
  background: transparent;
}

.img-max-150 {
  max-width: 150px;
}

.tag-list {
  display: inline-block;
  margin-left: 0;
}

.tag-list li {
  padding: 0;
}

.tag-list li > h5 {
  margin-top: 0;
  margin-bottom: 0;
}

/*RBT CSS End*/

/*Submenu CSS*/
/*.submenu {
    background: #fff;
    margin: 20px 20px 0;
    border-radius: 4px;
    padding: 15px 20px;
    -webkit-box-shadow: 1px 1px 3px #bfbfbf;
    -moz-box-shadow: 1px 1px 3px #bfbfbf;
    box-shadow: 1px 1px 3px #bfbfbf;
}*/

.submenu a {
  display: inline-block;
  font-size: 14px;
  transition: none;
  margin-right: 10px;
  font-weight: 400;
  padding: 5px 10px;
  border: 1px solid #42a5f5;
  color: #42a5f5;
  border-radius: 3px;
}

.submenu a:hover {
  text-decoration: none;
}

.submenu-active {
  background: #42a5f5;
  color: #fff !important;
}

/*Submenu CSS End*/

.qr-code > img {
  width: 100%;
  height: 100%;
}

.qr-code > p {
  margin-top: 5px;
  font-size: 8px;
}

.qr-below-link {
  font-weight: 400;
  font-size: 13px;
  margin-top: -15px;
  display: block;
}

.sn-text img {
  margin-right: 8px;
  width: 38px;
}

.sn-text p {
  line-height: 1.1em;
  margin-top: -2px;
  width: 220px;
}

.sn-card-container .panel-heading {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.node-more-btn {
  top: -10px;
  right: -20px;
}

/*card.css end*/
/*dashboard3.css will be used for only new styles for the sake of Dashboard 3 designs. Existing styles will be updated in respective files*/
body {
  /*background-color: #5EB3F7;*/
  font-family: "Roboto", sans-serif !important;
}

a {
  color: #42a5f5;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

h1 {
  font-size: 20px;
  font-weight: 700;
}

h2 {
  font-size: 18px;
  font-weight: 300;
}

h3 {
  font-size: 14px;
  font-weight: 700;
}

.gutter-20 .row {
  margin-right: -10px;
  margin-left: -10px;
}

.gutter-20 [class^="col-"],
.gutter-20 [class^=" col-"] {
  padding-right: 10px;
  padding-left: 10px;
}

header {
  background-color: #f2f2f2;
}

.panel-inner {
  padding-left: 20px;
  padding-right: 20px;
}

#page-wrapper {
  background-color: #f2f2f2;
  border: 0;
  min-height: 100vh;
}

.navbar-default {
  background: #fbfbfb;
}

.navbar-default.sidebar {
  background: #209cda;
}

.navbar-static-top {
  -webkit-box-shadow: 0 1px 3px rgba(170, 170, 170, 0.5);
  -moz-box-shadow: 0 1px 3px rgba(170, 170, 170, 0.5);
  box-shadow: 0 1px 3px rgba(170, 170, 170, 0.5);
}

.navbar-static-top .navbar-header {
  width: 100%;
}

.navbar-brand {
  padding-left: 0;
  margin: 15px 20px;
  height: 30px;
  float: none;
  display: block;
}

.panel-heading {
  min-height: 52px;
  border-bottom: 1px solid #e6e6e6;
  padding: 17px 20px;
}

.panel-title {
  font-size: 18px;
  color: #42a5f5;
}

.new-activity {
  margin-bottom: 20px;
}

.activity-list {
  padding: 20px 20px 0;
}

.al-text {
  padding-top: 6px;
}

.notification-wrap a.dropdown-toggle:hover,
.navbar-top-links li a:hover {
  background: #f2f2f2;
}

.navbar-top-links li:last-child {
  margin-right: 0;
}

.user-dropdown-parent {
  vertical-align: top;
}

.user-dropdown-parent a {
  font-weight: 300;
}

.sidebar ul li {
  border-bottom: 0;
}

.sidebar ul li a {
  color: #fff;
  font-weight: 300;
}

.sidebar ul li a:hover {
  /* background-color: #1E88E5; */
}

.sidebar ul li a:focus {
  /* background-color: #1E88E5; */
}

.sidebar ul li a.active {
  background-color: #1e88e5;
  position: relative;
}

.sidebar ul li a.active::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 5px;
  left: 0;
}

.title {
  color: #707070;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  line-height: 60px;
}

.breadcrumb {
  display: none;
}

.content {
  padding: 20px 5px;
  margin-top: 0;
}

.featured-block {
  background: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 3px #777;
  margin-bottom: 20px;
  overflow: hidden;
}

.featured-head {
  display: table;
  content: " ";
  clear: both;
  height: 70px;
  width: 100%;
  padding: 18px 20px 0;
  overflow: hidden;
  border-bottom: 1px solid #e6e6e6;
}

.f-digit {
  font-weight: 700;
  font-size: 40px;
  vertical-align: top;
  line-height: 0.8em;
}

.f-title {
  font-weight: 700;
  font-size: 15px;
  line-height: 2.2em;
  padding-left: 15px;
}

.featured-list {
  margin-bottom: 0;
}

.featured-list li {
  position: relative;
}

.featured-list li a:after {
  position: absolute;
  content: "\f105";
  right: 20px;
  font-family: "Font Awesome\ 5 Pro", "sans-serif";
  font-weight: 700;
  font-size: 14px;
  top: 8px;
  color: #828282;
}

.featured-list li a {
  display: flex;
  content: " ";
  clear: both;
  width: 100%;
  padding: 10px 43px 9px 20px;
  color: #828282;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;
}

.featured-list li a:hover {
  background: #f2f2f2;
}

.featured-list li:last-child a {
  border-bottom: none;
}

.blue-featured-block .f-digit {
  color: #42a5f5;
}

.blue-featured-block .f-title {
  color: #5abeff;
}

.blue-featured-block .featured-list li a:after {
  color: #288cdc;
}

.yellow-featured-block .f-digit {
  color: #feb405;
}

.yellow-featured-block .f-title {
  color: #ffd223;
}

.yellow-featured-block .featured-list li a:after {
  color: #e09623;
}

.green-featured-block .f-digit {
  color: #5ac102;
}

.green-featured-block .f-title {
  color: #73da1b;
}

.green-featured-block .featured-list li a:after {
  color: #329900;
}

.red-featured-block .f-digit {
  color: #fe763a;
}

.red-featured-block .f-title {
  color: #fe9462;
}

.red-featured-block .featured-list li a:after {
  color: #e0581c;
}

.snode-pagination {
  padding-right: 20px;
}

/*Buttons*/
/*Outline*/
.outlined-btn,
.small-add-btn {
  font-size: 14px;
  color: #42a5f5;
  border-color: #42a5f5;
  padding: 8px 12px;
  text-align: center;
  background: transparent;
  min-width: 38px;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.outlined-btn:hover,
.outlined-btn.focus,
.outlined-btn:focus,
.small-add-btn:hover,
.small-add-btn.focus,
.small-add-btn:focus {
  color: #fff;
  background-color: #42a5f5;
  border-color: #42a5f5;
  outline: none;
}

.outlined-btn:active,
.outlined-btn:active:hover,
.small-add-btn:active,
.small-add-btn:active:hover {
  color: #fff;
  background-color: #288cdc;
  border-color: #288cdc;
  outline: none;
}

.outlined-btn-red {
  color: #e0581c;
  border-color: #e0581c;
}

.outlined-btn-red:hover,
.outlined-btn-red.focus,
.outlined-btn-red:focus {
  color: #fff;
  background-color: #e0581c;
  border-color: #e0581c;
  outline: none;
}

.outlined-btn-red:active,
.outlined-btn-red:active:hover {
  color: #fff;
  background-color: #e0581c;
  border-color: #e0581c;
  outline: none;
}

.grey-btn {
  font-size: 14px;
  color: #333;
  background-color: #e6e6e6;
  border: 0;
  padding: 9px 12px;
  text-align: center;
  min-width: 140px;
}

.grey-btn:hover,
.grey-btn.focus,
.grey-btn:focus {
  color: #333;
  background-color: #f2f2f2;
  border: 0;
}

.grey-btn:active,
.grey-btn:active:hover {
  color: #333;
  background-color: #777;
  border: 0;
}

.blue-btn,
.btn-sn {
  font-size: 14px;
  color: #fff;
  background-color: #42a5f5;
  border: 0;
  padding: 9px 12px;
  text-align: center;
  min-width: 140px;
  font-weight: 300;
  text-transform: capitalize;

  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.blue-btn:hover,
.blue-btn.focus,
.blue-btn:focus,
.btn-sn:hover,
.btn-sn.focus,
.btn-sn:focus {
  color: #fff;
  background-color: #5abeff;
  border: 0;
}

.blue-btn:active,
.blue-btn:active:hover,
.btn-sn:active,
.btn-sn:active:hover {
  color: #fff;
  background-color: #288cdc;
  border: 0;
}

.small-add-btn {
  width: 38px;
  padding: 0;
  height: 38px;
  line-height: 38px;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.yellow-btn {
  font-size: 14px;
  color: #fff;
  background-color: #feb405;
  border: 0;
  padding: 8px 12px;
  text-align: center;
  min-width: 140px;
}

.yellow-btn:hover,
.yellow-btn.focus,
.yellow-btn:focus {
  color: #fff;
  background-color: #ffd223;
  border: 0;
}

.yellow-btn:active,
.yellow-btn:active:hover {
  color: #fff;
  background-color: #e09623;
  border: 0;
}

.green-btn {
  font-size: 14px;
  color: #fff;
  background-color: #5ac102;
  border: 0;
  padding: 8px 12px;
  text-align: center;
  min-width: 140px;
}

.green-btn:hover,
.green-btn.focus,
.green-btn:focus {
  color: #fff;
  background-color: #73da1b;
  border: 0;
}

.green-btn:active,
.green-btn:active:hover {
  color: #fff;
  background-color: #329900;
  border: 0;
}

.red-btn {
  font-size: 14px;
  color: #fff;
  background-color: #fe763a;
  border: 0;
  padding: 8px 12px;
  text-align: center;
  min-width: 140px;
}

.red-btn:hover,
.red-btn.focus,
.red-btn:focus {
  color: #fff;
  background-color: #fe9462;
  border: 0;
}

.red-btn:active,
.red-btn:active:hover {
  color: #fff;
  background-color: #e0581c;
  border: 0;
}

.icon-btn {
  position: relative;
  padding: 9px 12px 9px 25px;
}

.icon-btn .fa {
  position: absolute;
  left: 10px;
  top: 12px;
}

.btn-sm.yellow-btn,
.btn-sm.blue-btn,
.btn-sm.green-btn,
.btn-sm.red-btn,
.btn-sm.grey-btn {
  padding: 5px 10px;
  font-size: 12px;
  min-width: initial;
}

.icon-btn-round {
  width: 40px;
  height: 40px;
  padding: 0;
  line-height: 40px;
  font-size: 30px;
  border-radius: 50%;
  background: #9e9e9e;
  color: #fff;
  transition: all 300ms ease;
  transition-property: background, color;
}

.icon-btn-round:hover,
.icon-btn-round:hover:focus,
.icon-btn-round:hover:active:focus {
  background: #fff;
  color: #42a5f5;
}

.icon-btn-round:active:focus,
.icon-btn-round:focus {
  outline: none;
  color: #fff;
}

/*Button Group*/

.sn-btn-group {
  margin-bottom: 0;
  margin-left: 0;
}

.sn-btn-group li {
  padding-left: 0;
  padding-right: 0;
  height: 38px;
  text-align: center;
  min-width: 38px;
}

.sn-btn-group li a,
.sn-btn-group li button {
  background-color: #fff;
  display: block;
  color: #777;
  padding: 0 10px;
  border: 1px solid #e6e6e6;
  border-right: 0;
  height: 38px;
  line-height: 38px;
  font-size: 18px;
  border-radius: 0;
}

.sn-btn-group li a:hover,
.sn-btn-group li a:focus,
.sn-btn-group li a:active,
.sn-btn-group li button:hover,
.sn-btn-group li button:focus,
.sn-btn-group li button:active {
  background-color: #f2f2f2;
}

.sn-btn-group li:last-child {
  margin-right: 0;
}

.sn-btn-group li:first-child a,
.sn-btn-group li:first-child button {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.sn-btn-group li:last-child a,
.sn-btn-group li:last-child button {
  border-right: 1px solid #e6e6e6;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.sn-btn-group li a.live {
  background-color: #e6e6e6;
  color: #42a5f5;
  /*pointer-events: none;*/
}

/*New Form Styles*/
.form-header {
  padding: 0 20px 10px;
  border-bottom: 1px solid #e6e6e6;
}

.form-title {
  font-weight: 300;
  font-size: 18px;
  color: #42a5f5;
  margin: 0;
}

.outlined-text {
  font-size: 14px;
  color: #777;
}

.outlined-text .data-text {
  color: #777;
}

.form-body {
  padding: 30px 20px 15px;
}

.file-upload-image {
  width: 130px;
  margin: 0 20px 20px 0;
}

.file-upload-btn {
  position: relative;
  overflow: hidden;
  vertical-align: top;
}

.file-upload-btn input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  padding-top: 36px;
  font-size: 25px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.file-upload-btn > p.file-upload {
  height: 0;
  opacity: 0;
}

.file-upload-btn > p.file-upload a,
.file-upload-btn > p.file-upload .clearable-file-input {
  display: none;
}

.sn-input-group {
  clear: both;
}

.sn-input-group input.form-control,
.sn-input-group textarea.form-control {
  width: calc(100% - 58px);
  display: inline-block;
}

.sn-input-group .small-add-btn {
  float: right;
}

.sn-input-group label {
  display: block;
}

.asset-add-form .select2-container {
  width: calc(100%) !important;
}

.asset-textarea textarea {
  min-height: 289px;
}

/*.asset-add-form .input-group.date, .asset-add-form .input-daterange.input-group {*/
/*width: calc(100% - 58px);*/
/*}*/

.form-image-area {
  min-height: 180px;
  position: relative;
}

/*Login Page Design*/
.login-panel {
  width: 380px;
  margin: 0 auto;
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.075);
  -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.075);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.075);
}

.login-panel .panel-body {
  padding: 38px 38px 26px 38px;
}

.login-panel .panel-footer {
  padding: 17px 5px;
}

.login-form input[type="text"],
.login-form input[type="password"],
.login-form input[type="email"] {
  height: 50px;
}

.login-form label {
  margin-bottom: 16px;
}

.login-btn {
  margin-top: 20px;
  height: 50px;
  margin-bottom: 20px;
}

/*Asset Details Page*/
.asset-img {
  display: block;
  max-height: 180px;
}

.asset-map {
  margin-top: -20px;
  margin-bottom: 20px !important;
  height: 220px !important;
}

.asset-full {
  min-height: 488px;
}

.asset-full > .panel-body {
  height: 435px;
  overflow-y: auto;
}

.asset-mid {
  min-height: 260px;
}

.asset-small {
  min-height: 159px;
}

/*Partner Page*/
.partner-icon-col {
  width: 56px;
  text-align: center;
}

.pending-partner-row td {
  background: #fbfbfb;
  color: #777;
}

.pending-partner-row td img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.service-blocks {
  width: 40px;
  height: 35px;
  background: #42a5f5;
  color: #fff;
  line-height: 35px;
  border-radius: 3px;
  text-align: center;
}

.yellow-service-block {
  background: #feb405;
}

.red-service-block {
  background: #fe763a;
}

.green-service-block {
  background: #5ac102;
}

.dark-blue-service-block {
  background: royalblue;
}

.partners-table thead {
  display: none;
}

.fa-va-top {
  vertical-align: top;
}

.show-more {
  padding: 0;
}

.show-more a {
  padding: 10px 20px;
  text-align: center;
  display: block;
  position: relative;
}

.show-more a:after {
  content: "\f105";
  font-family: "Font Awesome\ 5 Pro", "sans-serif";
  position: absolute;
  right: 20px;
  top: 10px;
}

.featured-installed-node {
  height: 122px;
  text-align: center;
  line-height: 122px;
}

.featured-installed-node .fa {
  vertical-align: middle;
  margin-right: 10px;
}

.auth-body {
  background-color: #ffffff;
}

.logregpage {
  background: #42a5f5 url("../img/bg_pattern.png");
}

.btn.pull-right {
  margin-left: 20px;
}

.file-name {
  position: absolute;
  top: 75px;
  margin-left: 153px;
  color: #42a5f5;
  font-size: 14px;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.company-card {
  height: 285px;
  border: 0;
  position: relative;
}

.company-logo-container {
  height: 129px;
  position: relative;
}

.company-logo-container img {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.company-select-button {
  text-align: left;
  background: transparent;
  border: 0;
  outline: none;
  padding: 0;
  position: relative;
  -webkit-appearance: none !important;
}

.company-select-button .panel-title {
  display: block;
  padding: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #ddd;
  position: relative;
}

.c-body {
  padding: 20px;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.c-body::after,
.company-select-button .panel-title::after {
  position: absolute;
  content: "";
  background: transparent;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  -webkit-transition: background 0.3s;
  -moz-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s;
}

.company-card:hover .c-body::after,
.company-card:hover .company-select-button .panel-title::after {
  background: rgba(66, 165, 245, 0.3);
}

.c-body h4 {
  margin-bottom: 3px;
}

.c-body p {
  font-size: 17px;
  color: #aaaaaa;
}

/*.company-select-button .unapprove-message{
    display: none;
}

.company-select-button[disabled] .unapprove-message{
    display: block;
}*/

.unapprove-message {
  position: absolute;
  background: rgba(166, 166, 166, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 4px;
  margin: 0 6px;
}

.unapprove-message h3 {
  text-align: center;
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  background: #a6a6a6;
  top: 50%;
  padding: 5px 0;
  position: relative;
  transform: translateY(-50%);
}

.fill-height {
  position: relative;
  padding-bottom: 80px;
}

.panel.fill-height {
  margin-bottom: 0;
  padding-bottom: 60px;
}

.fill-height-footer {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.preview-image {
  width: 150px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.preview-details {
  width: calc(100% - 170px);
}

.preview-title {
  font-size: 15px;
  color: #42a5f5;
  font-weight: 400;
  margin-top: 0;
}

.preview-subtitle {
  color: #42a5f5;
  font-size: 14px;
  margin-top: 0;
}

.login-company-logo {
  margin-bottom: 40px;
}

.details-table > tbody > tr > th,
.details-table > tbody > tr > td {
  border-top: 0;
  padding: 2px 0;
  line-height: 1.75;
  vertical-align: top;
}

input.find-success,
input.find-success:focus {
  border-color: #73da1b;
}

.form-section-title {
  font-size: 14px;
  border-bottom: 1px solid #42a5f5;
  color: #42a5f5;
  line-height: 2em;
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: 300;
}

.form-section-title .fa {
  margin-right: 8px;
}

.gutter-20 [class^="col-"].form-separator,
.gutter-20 [class^=" col-"].form-separator {
  padding-right: 38px;
}

.find-success-icon {
  color: #73da1b;
  font-size: 18px;
  margin-top: 30px;
}

.field-required {
  font-weight: 700;
}

.field-required::after {
  content: "*";
  margin-left: 5px;
  color: #288cdc;
}

.text-btn {
  background: none;
  border: none;
  padding: 0;
  color: #5eb3f7;
  text-decoration: underline;
  outline: none;

  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
}

.text-btn:hover {
  color: #288cdc;
}

.heading-right-list {
  margin-bottom: 0;
  color: #777;
}

.heading-right-list li {
  border-right: 1px solid #777;
  padding-left: 20px;
  padding-right: 20px;
}

.heading-right-list li:last-child {
  border-right: 0;
  padding-right: 0;
}

.created-for-select {
  display: inline-block;
  border: 0;
  font-weight: 700;
  cursor: pointer;
  outline: none;
}

.heading-right-list label,
.created-for-select label {
  margin-bottom: 0;
}

.created-for-select .select2-container {
  width: auto !important;
  outline: none;
  margin-top: -3px;
}

.created-for-select .select2-container .select2-selection--single {
  border-color: transparent;
  height: 21px;
}

.created-for-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 21px;
  width: auto;
}

.created-for-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #42a5f5 transparent transparent transparent;
}

.created-for-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 21px;
}

.created-for-select
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  padding: 0 15px 0 0;
}

.created-for-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #9d9d9d;
}

.sn-tabs {
  border-bottom: 0;
}

.sn-tabs.nav-tabs > li {
  margin-bottom: 0;
}

.sn-tabs.nav-tabs > li > a {
  padding: 10px 18px;
  font-size: 14px;
  color: #a2a2a2;
  background-color: #ffffff;
  border: 0;
  -webkit-box-shadow: 0 -2px 5px -1px #bfbfbf inset;
  -moz-box-shadow: 0 -2px 5px -1px #bfbfbf inset;
  box-shadow: 0 -2px 5px -1px #bfbfbf inset;
  margin-right: 0;
}

.sn-tabs.nav-tabs > li.active > a,
.sn-tabs.nav-tabs > li.active > a:focus,
.sn-tabs.nav-tabs > li.active > a:hover {
  color: #42a5f5;
  border: 0;
  z-index: 9;
  -webkit-box-shadow: 5px -1px 5px -1px rgba(0, 7, 0, 0.1);
  -moz-box-shadow: 5px -1px 5px -1px rgba(0, 7, 0, 0.1);
  box-shadow: 5px -1px 5px -1px rgba(0, 7, 0, 0.1);
}

.sn-tab-content {
  background: #fff;
  padding-top: 10px;
  -webkit-box-shadow: 1px 1px 3px #bfbfbf;
  -moz-box-shadow: 1px 1px 3px #bfbfbf;
  box-shadow: 1px 1px 3px #bfbfbf;
  z-index: 7;
  position: relative;
  padding-bottom: 20px;
  border-radius: 4px;
  border-top-left-radius: 0;
  padding: 15px 15px 10px;
}

.sn-tab-content .noDataFound {
  padding: 40px 0 30px;
}

.subtitle {
  background: #ddd;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 20px;
}

.subtext {
  padding: 5px 20px;
}

.sharing-info {
  margin-bottom: 20px;
}

.sharing-info h4 {
  font-size: 14px;
  font-weight: 700;
  margin-top: 0;
}

.dot-area {
  position: relative;
  border: 1px dotted #42a5f5;
  padding: 5px 20px 0;
  color: #42a5f5;
}

.dot-area .checkbox label::before {
  border-color: #42a5f5;
}

.dot-area-hide {
  color: #42a5f5;
  position: absolute;
  z-index: 5;
  top: 3px;
  right: 3px;
  cursor: pointer;
}

.panel-right-header {
  margin-bottom: 20px;
  margin-right: -5px;
  margin-left: 0;
}

.panel-right-header > li {
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
}

.panel-right-header > li:last-child {
  padding-right: 5px;
}

.search-form .input-group {
  position: relative;
  margin-top: -5px;
}

.search-form .input-group .form-control:first-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-right: 35px;
}

.search-form-submit {
  position: absolute;
  top: 0;
  right: 0;
  height: 38px;
  z-index: 5;
  width: 38px;
  border: 0;
  background: transparent;
  color: #777;
}

.fake-link {
  color: #42a5f5;
  cursor: pointer;
}

.statistic-list {
  margin-bottom: 0;
}

.statistic-list li {
  padding: 10px 15px;
  border-bottom: 1px solid #dddddd;
  display: flex;
  align-items: center;
}

.statistic-list li p {
  font-size: 15px;
  font-weight: 700;
  color: #333;
  margin-left: 10px;
}

.address-tab-content {
  min-height: 360px;
}

.address-tab-content .noDataFound {
  padding: 20px;
}

.address-tab-content .details-table {
  margin-bottom: 0;
}

.address-switch-list {
  margin-left: 0;
  margin-bottom: 0;
  text-align: center;
  border-top: 1px solid #dddddd;
  border-bottom: 0;
}

.address-switch-list > li {
  width: 49%;
  padding: 0;
  border-right: 1px solid #dddddd;
}

.address-switch-list > li > a {
  border: 0;
  border-radius: 0;
  margin-right: 0;
  line-height: 0.5em;
}

.address-switch-list > li > a:hover,
.address-switch-list > li > a:focus {
  background-color: #ffffff;
}

.address-switch-list > li.active > a,
.address-switch-list > li.active > a:focus,
.address-switch-list > li.active > a:hover {
  border: 0;
  color: #333;
}

.address-switch-list li:last-child {
  border-right: 0;
}

.address-switch-list li span {
  cursor: pointer;
  font-size: 13px;
  text-decoration: underline;
  color: #42a5f5;
}

.address-switch-list li.active span {
  color: #333;
  text-decoration: none;
}

.pn-text {
  font-size: 16px;
  color: #333;
  margin-top: 3px;
}

.message {
  height: 57px;
  border-radius: 5px;
  padding: 0 0 0 15px !important;
  min-width: 300px;
  max-width: none !important;
}

.message.message-danger {
  background: #fe763a;
}

.message.message-success {
  background: #5ac102;
}

.message.message-warning {
  background: #feb405;
}

.message-icon {
  font-size: 30px;
  margin-right: 13px;
  vertical-align: sub;
  color: rgba(0, 0, 0, 0.27);
}

.message-text {
  color: #fff;
  font-size: 15px;
  line-height: 57px;
  margin-right: 15px;
}

.message-close {
  font-size: 28px;
  border-left: 1px solid #fff !important;
  opacity: 1;
  color: rgba(0, 0, 0, 0.27);
  text-shadow: none;
  padding: 0 20px !important;
  line-height: 56px;
}

/*Copied from Animate.css for message animation*/
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

.custom-file-upload {
  margin-top: 5px;
}

.custom-file-upload label {
  position: relative;
  height: 37px;
  line-height: 37px;
  padding-right: 37px;
  padding-left: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  display: block;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-file-upload label::before {
  content: "\f0c6";
  font-family: "Font Awesome\ 5 Pro", "sans-serif";
  font-size: 23px;
  color: #777;
  width: 37px;
  height: 35px;
  text-align: center;
  background: #e6e6e6;
  position: absolute;
  top: 0;
  right: 0;
}

.custom-file-upload input[type="file"] {
  position: absolute;
  top: 0;
  width: 0.1px;
  height: 0.1px;
  z-index: -1;
}

.has-error .custom-file-upload label {
  border-color: #a94442;
}

.hide-map {
  position: absolute;
  left: -100%;
}

.checklist-list {
  margin-bottom: 0;
}

.checklist-list li {
  font-weight: 700;
  padding: 15px 50px 15px 15px;
  border-bottom: 1px solid #d1d1d1;
  position: relative;
}

.checklist-list li[data-checked="True"]::after {
  position: absolute;
  content: "\f00c";
  font-family: "Font Awesome\ 5 Pro", "sans-serif";
  color: #5ac102;
  font-size: 16px;
  text-align: center;
  top: 25%;
  bottom: 0;
  right: 0;
  width: 50px;
}

.list-with-checkmark li {
  margin: 5px 5px 0;
}

.list-with-checkmark li::before {
  content: "\f14a";
  font-family: "Font Awesome\ 5 Pro", "sans-serif";
  color: #5ac102;
  font-size: 13px;
  vertical-align: middle;
  margin-right: 5px;
}

.mapicon .fa {
  font-size: 16px;
  margin-right: 5px;
}

.mapicon img {
  width: 15px;
  margin-right: 5px;
  vertical-align: baseline;
}

.mapicon p {
  margin-left: 20px;
}

.node-location-panel {
  min-height: 594px;
}
.node-location-panel #map {
  margin-top: -20px;
}

.address-info {
  margin-bottom: 40px;
}

.address-info h2 {
  margin-top: 0;
  font-weight: 400;
  margin-bottom: 20px;
}

.address-info p {
  margin-bottom: 10px;
}

.assign-checkbox {
  margin: 0;
  padding: 10px 0;
  border-bottom: 1px solid #f2f2f2;
}
.assign-checkbox input {
  box-shadow: none;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
}

.assign-checkbox input[type="number"] {
  width: 60px;
  text-align: center;
  padding: 1px 0;
  height: 30px;
}

.assign-checkbox input[type="text"] {
  display: block;
  width: 100%;
  height: 38px;
}

.assign-checkbox label {
  padding-left: 3px;
}

.check-data {
  width: calc(100% - 80px);
}

.check-wrap .checkbox {
  margin: 0;
}

.assign-checkbox + .checkbox {
  margin-top: 0;
}

.chi-dlt {
  padding: 8px 12px 8px 25px;
}

.access-deny {
  background: #eec8c5;
  border-radius: 5px;
  padding: 15px 20px;
  border: 1px solid #a32820;
}
.access-deny .fa {
  color: #a32820;
  font-size: 40px;
  margin-top: 5px;
  margin-right: 20px;
  opacity: 0.75;
}
.access-deny .deny-data {
  color: #a32820;
}

.deny-data strong {
  font-size: 20px;
}
.deny-data p {
  font-size: 16px;
}

.event-calendar-col {
  float: left;
  width: calc(100% - 375px);
  position: relative;
}

.event-add-col {
  float: right;
  width: 355px;
}

/*Fullcalendar IO Service Node Theme*/
body .fc {
  color: #42a5f5;
}

.fc table {
  font-size: 1.04em !important;
}

.fc-toolbar h2,
.fc th {
  font-weight: 400;
}

.fc button {
  background: #fff;
  box-shadow: none;
  text-shadow: none;
  color: #828282;
  padding: 0.5em 0.6em !important;
  height: auto !important;
  border-color: #e6e6e6;
}

.fc-state-active {
  background: #e6e6e6 !important;
}

.fc-day-grid-event {
  cursor: pointer;
  padding: 2px !important;
}

.fc-day-grid-event .fc-time {
  display: none;
}

.fc-title {
  text-transform: capitalize;
}
/*Fullcalendar IO Service Node Theme End*/

.modal .schedule-modal-dialog {
  width: auto;
  max-width: 360px;
}

.modal.in .schedule-modal-dialog {
  -webkit-transform: translate(0, 3%);
  -moz-transform: translate(0, 3%);
  -ms-transform: translate(0, 3%);
  -o-transform: translate(0, 3%);
  transform: translate(0, 3%);
  width: auto;
  max-width: 360px;
}
.schedule-modal-content {
  max-width: 360px;
  border-radius: 0;
  min-height: 400px;
  position: relative;
  padding-bottom: 50px;
}

.schedule-desc-wrap {
  background: #fff;
  padding-bottom: 15px;
  border-radius: 4px;
  -webkit-box-shadow: 1px 1px 3px #bfbfbf;
  -moz-box-shadow: 1px 1px 3px #bfbfbf;
  box-shadow: 1px 1px 3px #bfbfbf;
}

.schedule-desc-body {
  /*padding: 20px;*/
}

.schedule-title {
  background: #42a5f5;
  color: #fff;
  font-weight: 300;
  font-size: 18px;
  padding: 10px 15px;
  /*border-top-left-radius: 4px;*/
  /*border-top-right-radius: 4px;*/
}

.schedule-detail-item {
  border-bottom: 1px solid #e6e6e6;
  padding: 5px 0;
}

.schedule-detail-item ul {
  margin-bottom: 0;
}

.sc-item-icon {
  float: left;
  width: 35px;
  text-align: center;
}

.sc-item-icon .fa {
  color: #9c9c9c;
  margin-top: 2px;
}

.sc-item-detail {
  margin-left: 35px;
  padding-right: 5px;
}

.sc-link {
  display: block;
  font-weight: 400;
}

.ev-subtext {
  color: #777;
  margin: 0 5px;
}

.ev-subtext::before {
  content: "(";
}

.ev-subtext::after {
  content: ")";
}

#schedule-asset-list li {
  margin-bottom: 15px;
}

#schedule-asset-list li:last-child {
  margin-bottom: 0;
}

.orders-user-block {
  background: #fbfbfb;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  min-height: 100px;
  position: relative;
  display: flex;
  align-items: center;
}

.orders-user-img {
  float: left;
  margin-right: 10px;
  width: 80px;
  height: 80px;
}

.orders-user-detail {
  flex: 1;
}

.orders-user-detail h4 {
  color: #42a5f5;
  font-size: 18px;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
}

.orders-user-detail p {
}

.orders-user-block.user-block-small {
  min-height: 80px;
}

.orders-user-block.user-block-small > .orders-user-img {
  width: 60px;
  height: 60px;
}

.orders-user-block.user-block-small > .orders-user-detail h4 {
  font-size: 16px;
}

.orders-user-block.selected-user-block {
  border: 0;
  padding: 0;
  margin: 0;
  min-height: 10px;
}

.orders-user-block.selected-user-block > .orders-user-img {
  width: 60px;
  height: 60px;
}

/*
.orders-user-block.selected-user-block > .orders-user-detail{
    margin-left: 75px;
}
*/

.orders-user-block.selected-user-block > .orders-user-detail > h4 {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 5px;
}

.orders-user-block.selected-user-block > .orders-user-detail > p {
  color: #42a5f5;
  font-size: 16px;
}

/*Spinner Loader*/
.spinner {
  margin: 100px auto;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 13px;
  height: 13px;
  background-color: #42a5f5;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/*Calendar Loader*/
.loader-wrapper {
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  /*background: rgba(33, 150, 243, 0.11);*/
  z-index: 5;
}

.loader-wrapper .spinner {
  margin-top: 300px;
}

.sn-crop-up-btn {
  margin-left: 0;
  margin-bottom: 13px;
}

.sn-crop-wrap {
  margin-bottom: 20px;
}

.sn-crop-wrap img {
  max-height: 300px;
}

.show-on-active {
  margin-top: 7px;
}

.sc-modal-mod-list {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 10px 4px;
}
.sc-modal-mod-list li {
  padding-right: 10px;
  padding-left: 0;
}

.crop-area-placeholder {
  height: 400px;
}

.crop-area-placeholder p {
  font-size: 20px;
  color: #333;
  opacity: 0.6;
  position: relative;
  top: 25%;
}
.inv-act-list {
  position: absolute;
  top: 50%;
  margin-top: 16px;
  display: flex;
  left: 0;
  right: 0;
}

.inv-act-list .btn {
  flex: 0.33333;
  min-width: auto;
  border-radius: 0;
}

.btn-group.inv-act-list
  > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group.inv-act-list > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#fileUploadModal.in .modal-dialog {
  -webkit-transform: translate(0, 25%);
  -ms-transform: translate(0, 25%);
  -o-transform: translate(0, 25%);
  transform: translate(0, 25%);
}

#fileUploadModal.in .modal-dialog-default {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.file-modal-tabs {
  border-color: #42a5f5;
}

.file-modal-tabs > li > a {
  border: 0;
  border-radius: 0;
  padding: 10px 30px;
}

.file-modal-tabs > li.active > a,
.file-modal-tabs > li.active > a:focus,
.file-modal-tabs > li.active > a:hover {
  background: #42a5f5;
  color: #fff;
  border: 0;
}

.file-list-container {
  height: 256px;
  overflow-x: hidden;
}

.modal-file-list {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 5px;
}

.modal-file-list li {
  margin: 5px;
  width: 18%;
  float: left;
}

.modal-file-list li .tooltip-arrow {
  border-bottom-color: #42a5f5 !important;
}

.modal-file-list li .tooltip.in {
  opacity: 1 !important;
}

.file-wrap {
  text-align: center;
  overflow: hidden;
  padding: 10px 5px 5px;
  cursor: pointer;

  -webkit-transition: background 0.3s;
  -moz-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s;
}

.file-wrap i {
  display: block;
  font-size: 30px;
  margin-bottom: 10px;
}

.file-wrap span {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.file-wrap:hover {
  background: #e6e6e6;
}

.file-wrap.selected {
  background: #42a5f5;
  color: #fff;
}

.file-select-container {
  margin-top: 20px;
}

.file-select-container p {
  line-height: 36px;
}

.file-select-container .green-btn {
  min-width: 75px;
}

#fileUpload {
  height: 236px;
  border: 2px dashed #42a5f5;
  border-radius: 5px;
  margin-top: 20px;
}

#fileUpload .dz-message {
  font-size: 18px;
  font-weight: 400;
  color: #827f7f;
  margin: 3em 0;
}

.file-modal-loader {
  background: rgba(245, 245, 245, 0.6);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.file-modal-loader .spinner {
  margin-top: 0;
  position: relative;
  top: 50%;
}

.asset-doc-list {
  padding-top: 5px;
}
.asset-doc-list li {
  clear: both;
  border-bottom: 1px solid #e6e6e6;
  display: inline-block;
  width: 100%;
  padding: 1px 0 3px 0;
}
.asset-doc-list li > span {
  display: inline-block;
  float: left;
  width: calc(100% - 15px);
}
.asset-doc-list li > i {
  display: inline-block;
  float: right;
}
.remove-file {
  cursor: pointer;
  color: #fe763a;
}

.table-view-wrap {
  width: 67px;
  padding-right: 20px;
}

.table-view-btn {
  background: #ffffff;
  color: #42a5f5;
  padding: 6px 15px;
  border: 1px solid #42a5f5;
  border-radius: 3px;
}

.table-view-btn.focus,
.table-view-btn:focus,
.table-view-btn:hover {
  background: #42a5f5;
  color: #fff;
}

.progress-list {
  margin-top: 20px;
}

.progress-list .activity-logo {
  width: 65px;
  height: 65px;
}

.progress-list li {
  position: relative;
  margin-left: 65px;
  padding-left: 15px;
  text-align: center;
}

.progress-list li::before {
  content: "";
  width: 65px;
  background: #5ac102;
  height: 20px;
  position: absolute;
  left: -65px;
  top: 50%;
  transform: translateY(-50%);
}

.progress-list li::after {
  content: "";
  width: 14px;
  height: 14px;
  background: #5ac102;
  position: absolute;
  top: 50%;
  transform: rotate(45deg);
  left: -7px;
  margin-top: -7px;
}

.progress-list li:first-child {
  margin-left: 0;
  padding-left: 5px;
}

.progress-list li:first-child::before,
.progress-list li:first-child::after {
  width: 0;
  height: 0;
}
.vprogress-list .orders-user-block {
  margin-bottom: 0;
}
.progress-down-array {
  font-size: 21px;
  color: #5ac102;
  margin-bottom: 5px;
  margin-top: 5px;
}

.node {
  border: 1px solid #333;
  padding: 8px;
  width: 113px;
  height: 198px;
  border-radius: 10px;
}

.node .screw-hole {
  width: 8px;
  height: 8px;
  border: 1px solid #333;
  border-radius: 100px;
}

.node .black-logo {
  width: 85px;
  margin-bottom: 8px;
}

.tray-top .Select-menu-outer {
  top: auto;
  bottom: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}
.tray-top .Select-option:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.tray-top .Select-option:last-child {
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}
.tray-top .Select.is-open > .Select-control {
  border-top-right-radius: unset;
  border-top-left-radius: unset;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.notification-logo:before {
  content: "\F1C5";
  font-size: 30px;
  font-family: "Font Awesome\ 5 Pro";
  color: rgb(100, 100, 100);
  display: flex;
  position: absolute;
  text-align: center;
  flex: 1;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.import-entity {
  display: flex;
  justify-content: space-between;
}
.import-name {
  margin-top: 10px;
  flex: 0.4;
}
.import-selection {
  flex: 0.6;
}

.modal-position-to-top.modal-dialog {
  transform: translate(0, 0) !important;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.table-label {
  padding: 4px;
  display: inline-block;
  min-width: 70px;
}

.w-100 {
  width: 100px;
}

.image-flash {
  -webkit-animation: flash linear 1s infinite;
  animation: flash linear 1s infinite;
}
@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.btn-link {
  color: #41a5f7;
}

.btn-link:hover {
  text-decoration: none;
}

.pac-container {
  z-index: 1100 !important;
}
.d-flex {
  display: flex;
}
.justify-space-between {
  justify-content: space-between;
}
.bg-transparent {
  background: transparent;
}

.password-wrapper {
  position: relative;
}

.password-wrapper input.form-control {
  padding-right: 40px;
}

.toggle-pass {
  position: absolute;
  top: 15px;
  right: 0;
  width: 30px;
}
