.sn-card {
  .ant-card-body {
    padding: 12px 18px;
  }
}

.sn-grey-card {
  box-shadow: $primary-box-shadow;
  background-color: #f0f0f0;
  .ant-card-body {
    padding: 12px 18px;
  }
}
.sn-card-data-head {
  color: #000000d9;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 2px;

  .sn-card-data-head-wrapper {
    display: flex;
    align-items: center;
    .sn-card-data-head-title {
      display: inline-block;
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .sn-card-data-head-extra {
      float: right;
      margin-left: auto;
      color: #000000d9;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
.sn-card-data {
  //   width: calc(100% - 8px);
  box-shadow: $primary-box-shadow;

  .ant-card-body {
    padding: 12px;
  }
  .ant-card-meta-title {
    font-weight: 400;
    margin-bottom: 0 !important;
  }
}
.sn-details-icon-item {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
  > li {
    display: flex;
    zoom: 1;
  }
  .item-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-top: 1.5px;
    margin-right: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    background-color: #fafafa;
    border-radius: 20px;
  }
  .item-text {
    flex: 1 1;
    margin-right: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .ant-typography {
      .ant-typography-copy {
        display: none !important;
      }
      .anticon-copy {
        color: $text-color-secondary;
      }
      &:hover {
        .ant-typography-copy {
          display: inline-block !important;
        }
      }
    }
  }
}
