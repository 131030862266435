.tos-footer{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.tos-lang{

}

.tos-lang li{
    cursor: pointer;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
}

.tos-lang li:first-child{
    border-right: 2px solid #41a5f7;
}

.tos-lang li.active{
    color: #41a5f7;
}

.tos-content{
    max-height: 400px;
    overflow-y: auto;
}

.tos-content p {
    margin-bottom: 15px;
    text-align: justify;
    margin-right: 10px;
    line-height: 1.75em;
}