@import "../../../sass/base.scss";
.sn-icon-btn {
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    font-size 0.3s ease, transform 0.3s ease-in-out;
  will-change: transform;
  &:active,
  &:focus,
  &:hover {
    color: $primary-color;
  }
}
.sn-icon-btn-small {
  font-size: 14px;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    font-size 0.3s ease, transform 0.3s ease-in-out;
  will-change: transform;
  color: rgba(0, 0, 0, 0.45);
  &:active,
  &:focus,
  &:hover {
    color: $primary-color;
  }
}
.sn-fak-icon {
  height: 1em;
  vertical-align: -0.125em;
  &:before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }
}

.sn-icon-link {
  color: $primary-color;
}
