.sn-image {
  border-radius: 4px;

  // margin: 0 8px 8px 0;

  display: flex !important;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  // max-width: 100%;
  img {
    width: 100%;
    height: auto;
    object-fit: scale-down;
  }

  .sn-btn-icon-image {
    z-index: 10;
    width: 16px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s;
    margin: 0 4px;
    &:hover {
      color: #fff;
    }
  }
}
.ant-image-mask {
  border-radius: 4px;
}
.sn-image-with-remove {
  width: 48px;
  height: 48px;
  .remove-btn {
    position: absolute;
    right: -8px;
    top: -8px;
    font-size: 18px;
  }
}
