.sn-form {
  section + section {
    margin-top: 8px;
  }

  .ant-row {
    .ant-col {
      section {
        margin-bottom: 12px;
      }
    }
  }
}

.sn-form-text {
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  font-weight: 300;
}

.ant-picker {
  width: 100%;
}
.ant-form-large .ant-form-item-label > label {
  height: 29px;
}
.sn-input-addon {
  display: table !important;
}
.ant-input-group {
  height: fit-content;
  > .ant-input-affix-wrapper {
    height: 100%;
  }
}

.ant-input-group.ant-input-group-compact
  > *:first-child
  .sn-form-duration-item {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.ant-input-group.ant-input-group-compact > *:last-child .sn-form-duration-item {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.sn-tabs-container {
  .ant-tabs-nav {
    margin: 0;
    .ant-tabs-nav-wrap {
      z-index: 1;
    }
  }
  .ant-tabs-content-holder {
    margin-top: -1px;
  }
}

// SELECT
.sn-select-option-item {
  &.divider-option {
    &:last-child {
      border-bottom: 1px solid #f0f0f0;
    }
  }

  .ant-list-item-meta-content {
    margin-top: -3px;
    .ant-list-item-meta-title {
      margin: 0;
    }

    .ant-list-item-meta-description {
      font-size: 12px;
      white-space: pre-line;
      /* These are technically the same, but use both */
      overflow-wrap: break-word;
      word-wrap: break-word;

      -ms-word-break: break-all;
      /* This is the dangerous one in WebKit, as it breaks things wherever */
      word-break: break-all;
      /* Instead use this non-standard one: */
      word-break: break-word;

      /* Adds a hyphen where the word breaks, if supported (No Blink) */
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }
  }
  .ant-tag {
    line-height: 16px;
    font-size: 10px;
    margin: 0 4px;
  }
}

.sn-tabs-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: transparent;
}
.sn-tabs-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme="compact"]
  .sn-tabs-container
  > .ant-tabs-card
  .ant-tabs-tab-active {
  background: #fff;
  z-index: 1;
}

.sn-form-collapse {
  margin-top: 8px;
  .ant-collapse-header {
    color: $primary-color !important;
  }
  &-panel {
    .ant-collapse-content {
      padding-top: 8px;
    }
  }
}

.ant-typography {
  color: inherit;
}
.ant-tag {
  font-weight: initial;
  .ant-typography {
    font-weight: initial;
  }
}
.sn-list {
  .ant-list-items {
    .ant-list-item {
      padding: 10px 0;
      .ant-list-item-meta-title {
        font-weight: normal;
      }
      .ant-list-item-meta-description {
        font-size: 12px;
      }
    }
  }
}
.sn-list-item-meta-data {
  .ant-list-item-meta-title {
    margin: 0 !important;
  }
  .ant-list-item-meta-avatar {
    margin-right: 6px;
  }
  .ant-list-item-meta-content {
    margin-top: -4px;
  }
}
.ant-list-item-meta-content {
  width: fit-content !important;
}
.option-item-has-image {
  .ant-list-item-meta-avatar {
    margin-right: 12px;
  }
  .ant-list-item-meta-content {
    margin-top: -3px;
    .ant-list-item-meta-title {
      margin: 0;
    }
    .ant-list-item-meta-description {
      font-size: 12px;
    }
  }
}

.sn-user-item {
  .ant-list-item-meta-title {
    margin: 0 !important;
  }
  .ant-list-item-meta-avatar {
    margin-right: 6px;
  }
  .ant-list-item-meta-content {
    margin-top: -4px;
  }
}

.user-meta-data {
  .ant-card-meta-title {
    font-size: 16px;
    margin-bottom: 0px !important;
  }

  .far {
    color: black;
  }

  .details-icon-item {
    p {
      position: relative;
      margin-bottom: 8px;
      .fas {
        width: 14px;
      }
    }
  }
}
.ant-picker-ok {
  .ant-btn {
    padding: 2px 26px !important;
    height: 30px;
  }
}
.ant-picker-footer {
  min-width: 140px !important;
  width: 100%;
}
.ant-image-preview-root {
  .ant-image-preview-mask {
    z-index: 1010 !important;
    background-color: rgba(0, 0, 0, 0.9) !important;
  }
}
.sn-meta-data {
  width: 100%;
  .ant-card-meta-avatar {
    font-size: 28px;
  }
  .ant-card-meta-detail {
    .ant-card-meta-title {
      margin-bottom: 0 !important;
    }
  }
  .ant-card-meta-description {
    font-size: 14px;
    font-weight: normal;
  }
}
.sn-meta-item {
  width: 100%;

  .ant-card-head {
    height: fit-content;
    min-height: auto;
    padding: 4px 12px 2px 18px;
    .ant-card-head-wrapper {
      min-height: auto;
      height: fit-content;
      .ant-card-head-title,
      .ant-card-extra {
        padding: 0 !important;
      }
    }
  }
  .sn-meta-item-title-wrapper {
    display: flex;
    align-items: center;
    .sn-meta-item-title {
      display: inline-block;
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .sn-meta-item-extra {
      float: right;
      margin-left: auto;
      color: #000000d9;
      font-weight: 400;
      font-size: 14px;
    }
  }
  .ant-list-item-meta-avatar {
    margin-right: 10px;
  }
  .ant-list-item-meta-title {
    margin: 0;
  }

  .ant-card-meta-avatar {
    padding-right: 10px;
  }

  .sn-meta-item-actions {
    svg {
      font-size: 18px;
    }
    position: absolute;
    top: 7px;
    right: 12px;
  }
}

.sn-details-item-with-icon {
  display: flex;
}
.ant-list-item-meta {
  h4 {
    margin-top: 0 !important;
  }
}

.sn-meta-item {
  .ant-card-body {
    padding: 10px;

    .ant-card-meta-avatar {
      padding-right: 10px;
    }

    .ant-card-meta-title {
      font-size: 14px;
      margin-bottom: 0 !important;
    }
  }
}
.sn-items-list {
  .ant-list-item {
    border-bottom: none;
    padding-top: 0;
  }
}
.ant-alert {
  align-items: flex-start !important;
  > .anticon {
    margin-top: 3px;
  }
}
.ant-result-content {
  display: flex;
  justify-content: center;
  .page-not-found-extra {
    text-align: center;
  }
}
.ant-dropdown-menu-item {
  &.selected {
    background-color: #e6f7ff;
  }
}

.sn-checkbox-icon {
  &.ant-checkbox-wrapper-checked {
    svg {
      color: #5ac102;
      font-weight: 900;
    }
  }
}

.ant-select-selector {
  width: 100%;
}
.sn-dropdown-with-description {
  max-width: 330px;
  .ant-dropdown-menu-item-disabled {
    .ant-dropdown-menu-title-content {
      span {
        color: inherit !important;
      }
    }
  }
  .ant-dropdown-menu-title-content {
    span {
      white-space: pre-line;
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      -webkit-hyphens: auto;
      hyphens: auto;
    }
  }
}

.sn-descriptions-no-colon {
  .ant-descriptions-item-label {
    &::after {
      content: "";
    }
  }
}

.sn-radio-hidden-check {
  .ant-radio {
    display: none;
  }
}
.sn-checkbox-hidden-check {
  .ant-checkbox {
    display: none;
  }
  .ant-checkbox-checked + span {
    color: $primary-color;
  }
}

.sn-list-items {
  .ant-list-item-meta {
    align-items: center !important;
  }
}

.select-with-default-options {
  .default-option ~ .default-option {
    border-bottom: 1px solid #f0f0f0;
  }
}
