@import "../../../../sass/base.scss";
.sn-auth-change-language {
  .ant-radio-wrapper {
    .ant-radio {
      display: none;
    }
    &:first-child {
      &::after {
        position: absolute;
        top: 11px;
        right: -3px;
        width: 1px;
        height: 20px;
        background-color: #d9d9d9;
        content: "";
      }
    }
  }
  .ant-radio-wrapper {
    color: $primary-color;
  }
  .ant-radio-wrapper-checked {
    color: rgba(0, 0, 0, 0.85);
  }
}
